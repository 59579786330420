$default--black: rgba(black, 0.87);
$default--white: #fff;
$default--primary-50: #fcfdfe;
$default--primary-100: #edf3f9;
$default--primary-200: #d4e1ec;
$default--primary-300: #bbcfdf;
$default--primary-400: #a2bdd1;
$default--primary-500: #89abc4;
$default--primary-600: #6f98b7;
$default--primary-700: #5686aa;
$default--primary-800: #3d749c;
$default--primary-900: #24628f;
$default--accent-50: #dffff9;
$default--accent-100: #cdf9f0;
$default--accent-200: #b6f0e3;
$default--accent-300: #9adecf;
$default--accent-400: #8cd9c9;
$default--accent-500: #76c9b7;
$default--accent-600: #63c3ae;
$default--accent-700: #4ab29c;
$default--accent-800: #39b096;
$default--accent-900: #28b293;
$default--warn-50: #ffebee;
$default--warn-100: #ffcdd2;
$default--warn-200: #ef9a9a;
$default--warn-300: #e57373;
$default--warn-400: #ef5350;
$default--warn-500: #e36260;
$default--warn-600: #e14c49;
$default--warn-700: #c24040;
$default--warn-800: #c73434;
$default--warn-900: #b22847;

$orange--black: rgba(black, 0.87);
$orange--white: #fff;
$orange--primary-50: #fff3e0;
$orange--primary-100: #ffe0b2;
$orange--primary-200: #ffcc80;
$orange--primary-300: #ffb74d;
$orange--primary-400: #ffa726;
$orange--primary-500: #ff9800;
$orange--primary-600: #fb8c00;
$orange--primary-700: #f57c00;
$orange--primary-800: #ef6c00;
$orange--primary-900: #e65100;
$orange--accent-50: #e6f5ea;
$orange--accent-100: #c4e5cc;
$orange--accent-200: #9ed4ac;
$orange--accent-300: #76c58c;
$orange--accent-400: #58b974;
$orange--accent-500: #38ac5c;
$orange--accent-600: #309d52;
$orange--accent-700: #268b46;
$orange--accent-800: #1e7a3c;
$orange--accent-900: #0e5b29;

$default-dark--black: #fff;
$default-dark--white: rgba(black, 0.87);
$default-dark--primary-50: #010a1b;
$default-dark--primary-100: #091b31;
$default-dark--primary-200: #122c47;
$default-dark--primary-300: #1a3d5d;
$default-dark--primary-400: #224e73;
$default-dark--primary-500: #2b5f89;
$default-dark--primary-600: #33709f;
$default-dark--primary-700: #3b81b5;
$default-dark--primary-800: #4492cb;
$default-dark--primary-900: #4ca3e1;
$default-dark--accent-50: #001514;
$default-dark--accent-100: #1b1110;
$default-dark--accent-200: #290f0e;
$default-dark--accent-300: #360d0c;
$default-dark--accent-400: #440b0a;
$default-dark--accent-500: #4b0a09;
$default-dark--accent-600: #510908;
$default-dark--accent-700: #5e0706;
$default-dark--accent-800: #650605;
$default-dark--accent-900: #6b0504;
$default-dark--warn-50: #4d4748;
$default-dark--warn-100: #463739;
$default-dark--warn-200: #5d3838;
$default-dark--warn-300: #823e3e;
$default-dark--warn-400: #b73228;
$default-dark--warn-500: #b73228;
$default-dark--warn-600: #b73228;
$default-dark--warn-700: #b73228;
$default-dark--warn-800: #b73228;
$default-dark--warn-900: #b71c1c;

$orange-dark--black: #fff;
$orange-dark--white: #160800;
$orange-dark--primary-50: #001514;
$orange-dark--primary-100: #1b1110;
$orange-dark--primary-200: #290f0e;
$orange-dark--primary-300: #360d0c;
$orange-dark--primary-400: #440b0a;
$orange-dark--primary-500: #4b0a09;
$orange-dark--primary-600: #510908;
$orange-dark--primary-700: #5e0706;
$orange-dark--primary-800: #650605;
$orange-dark--primary-900: #6b0504;
$orange-dark--accent-50: #031911;
$orange-dark--accent-100: #042117;
$orange-dark--accent-200: #05291c;
$orange-dark--accent-300: #063122;
$orange-dark--accent-400: #073928;
$orange-dark--accent-500: #09422d;
$orange-dark--accent-600: #0a4a33;
$orange-dark--accent-700: #0b5239;
$orange-dark--accent-800: #0c5a3e;
$orange-dark--accent-900: #0d6244;

// update global variables and classes when theme / mode is changed.
@mixin update-theme-colors($colors) {
  @each $key, $color in $colors {
    // global variables that stores theme colors
    --#{$key}: #{$color};

    // Background and text color classes
    .cipo-bg--#{$key} {
      background-color: var(--#{$key});
    }

    .cipo-text--#{$key} {
      color: var(--#{$key});
    }

    .cipo-bg-hover--#{$key} {
      &:hover {
        background-color: $color;
      }
    }
  }
}

$cipo-black-50: #f5f5f5;
$cipo-black-100: #eeeeee;
$cipo-black-200: #e0e0e0;
$cipo-black-300: #bdbdbd;
$cipo-black-400: #9e9e9e;
$cipo-black-500: #757575;
$cipo-black-600: #616161;
$cipo-black-700: #424242;
$cipo-black-800: #212121;
$cipo-black-900: #000000;

$cipo-black: (
  "black-50": $cipo-black-50,
  "black-100": $cipo-black-100,
  "black-200": $cipo-black-200,
  "black-300": $cipo-black-300,
  "black-400": $cipo-black-400,
  "black-500": $cipo-black-500,
  "black-600": $cipo-black-600,
  "black-700": $cipo-black-700,
  "black-800": $cipo-black-800,
  "black-900": $cipo-black-900,
);

.cipo,
.cipo-dialog {
  @each $key, $color in $cipo-black {
    // Background and text color classes
    .cipo-bg--#{$key} {
      background-color: $color;
    }

    .cipo-text--#{$key} {
      color: $color;
    }

    .cipo-bg-hover--#{$key} {
      &:hover {
        background-color: $color;
      }
    }
  }
}
