@import "gridstack/dist/gridstack.min.css";
@import "gridstack/dist/gridstack-extra.min.css";

.grid-stack.form-builder-grid {
  .grid-stack-item-content {
    align-content: end;

    .mat-mdc-form-field {
      width: 100%;

      .mat-mdc-form-field-infix {
        padding-bottom: 0;
      }
    }
  }
}
.gridstack-pattern {
  $grid-height: 75px;
  $grid-width: calc(100% / 12);

  background-color: var(--accent-50);
  background-image: linear-gradient(
      to right,
      var(--primary-100) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, var(--primary-100) 1px, transparent 1px);

  background-size: $grid-width $grid-height;
}
