$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

$font-weights: (
  "light": $font-weight-light,
  "regular": $font-weight-regular,
  "medium": $font-weight-medium,
  "bold": $font-weight-bold,
  "black": $font-weight-black,
);

@each $key, $value in $font-weights {
  .cipo,
  .cipo-dialog {
    .cipo-text-weight--#{$key} {
      font-weight: $value;
    }
  }
}
