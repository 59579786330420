treecontrol {
    /* prevent user selection */
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* default */
    /* font-family: Verdana, Helvetica, Arial, sans-serif;
    font-size:13px;
    color: #555;
    text-decoration: none;*/
}
treecontrol li>i:before, treecontrol i:after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font: normal normal normal 24px/1 "Material Design Icons";

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 3px;
    position: relative;
    top: 2px;
    color: #868686;
    
    
}
    treecontrol li > .tree-label:before {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font: normal normal normal 24px/1 "Material Design Icons";
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
treecontrol i.tree-branch-head:before{
    font: normal normal normal 17px/1 FontAwesome;

}
treecontrol i:before {
    font-size: 15px;
    min-width: 10px;
}
treecontrol ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border: none;
}

treecontrol li {
    position: relative;
    padding: 5px 0;
    white-space: nowrap;
    padding: 5px 0 5px 15px;
}
treecontrol li:last-child {
    padding-bottom: 0;
}
treecontrol li.tree-expanded i.tree-leaf-head, treecontrol li.tree-collapsed i.tree-leaf-head {display:none;}
treecontrol li.tree-expanded i.tree-branch-head, treecontrol li.tree-collapsed i.tree-branch-head {
    position: absolute;
    width: 15px;
    left: 0;
    top: 7px;
}

treecontrol li.tree-leaf i.tree-branch-head {display:none;}
treecontrol li.tree-leaf i.tree-leaf-head {display:none;}

treecontrol li i.tree-branch-head {
    cursor: pointer;
}

treecontrol li .tree-label {
    cursor: pointer;
    /* display: inline; */
    position: relative;
    padding: 3px 0 3px 30px;
    
}
treecontrol li .tree-label div {
    overflow: hidden;
    text-overflow: ellipsis;
}
treecontrol li .tree-label:before {
    content: "\f07b";
    content: "\F024B";
    position: absolute;
    top: 0;
    left: 0;
} 
/*treecontrol li.tree-leaf .tree-label:before {
    content: "\f024c";
}*/
treecontrol li .tree-unselectable {
    cursor: not-allowed;
}

treecontrol.tree-classic li.tree-expanded i.tree-branch-head {
    padding: 0;
}
/*treecontrol.tree-classic li.tree-expanded i.tree-branch-head:after {
    content: "\f07b";
} 
treecontrol.tree-classic li.tree-collapsed i.tree-branch-head:after {
    content: "\f07b";
    color: #868686;
}
treecontrol.tree-classic li.a7>i.tree-branch-head:after {
    content: "\f07c";
    color: #1ab394;
}


*/

treecontrol.tree-classic li.tree-expanded i.tree-branch-head:before {
    content: "\f0d7";
}

treecontrol.tree-classic li.tree-collapsed i.tree-branch-head {
    padding: 0;
}

treecontrol.tree-classic li.tree-collapsed i.tree-branch-head:before {
    content: "\f0da";
    color: #868686;
        margin-left: 3px;
}
treecontrol.tree-classic li.tree-leaf i.tree-leaf-head {
    padding: 1px 10px;
    background: orange;
}

treecontrol.tree-classic li .tree-selected {
    /*background-color: #aaddff; */
    font-weight: bold;
}
treecontrol.tree-classic li .tree-selected:before {
    content: "\F0770";
    /* color: #1ab394;*/
}

treecontrol.tree-classic li .tree-unselectable, treecontrol.tree-classic li .tree-unselectable:before {
    color: #ddd;
}

treecontrol.tree-light li.tree-expanded i.tree-branch-head {
    padding: 1px 10px;
    background: blue;
}

treecontrol.tree-light li.tree-collapsed i.tree-branch-head {
    padding: 1px 10px;
    background: yellow;
}

treecontrol.tree-light li.tree-leaf i.tree-leaf-head {
    padding: 1px 10px;
    width: 16px; height: 16px;
    background: none no-repeat;
}

treecontrol.tree-light li .tree-selected {
    font-weight: bold;
}

treecontrol.tree-light li .tree-unselectable {
    color: #ddd;
}

treecontrol.tree-dark li.tree-expanded i.tree-branch-head {
    padding: 1px 10px;
}

treecontrol.tree-dark li.tree-collapsed i.tree-branch-head {
    padding: 1px 10px;
}

treecontrol.tree-dark li.tree-leaf i.tree-leaf-head {
    padding: 1px 10px;
    width: 16px; height: 16px;
    background: none no-repeat;
}

treecontrol.tree-dark li .tree-selected {
    font-weight: bold;
}

treecontrol.tree-dark li .tree-unselectable {
    color: #777;
}

treecontrol.tree-dark {
    color: #ddd;
}

treecontrol.tree-boot {
    color: #428bca;
    font-family: inherit;
    font-size: inherit;
}

treecontrol.tree-boot > ul > li {
    padding: 0;
}

treecontrol.tree-boot li > .tree-label {
    display: block;
    padding: 3px 0;
    border-radius: 4px;
}

treecontrol.tree-boot i.tree-branch-head,
treecontrol.tree-boot i.tree-leaf-head {
    float: left;
    height: 16px;
    margin: 5px;
}

treecontrol.tree-boot li.tree-expanded i.tree-branch-head {
    padding: 1px 10px;
}

treecontrol.tree-boot li.tree-collapsed i.tree-branch-head {
    padding: 1px 10px;
}

treecontrol.tree-boot li.tree-leaf i.tree-leaf-head {
    padding: 1px 10px;
}

treecontrol.tree-boot .tree-label.tree-selected,
treecontrol.tree-boot .tree-label.tree-selected:hover {
    color: #fff;
    background-color: #428bca;
}

treecontrol.tree-boot .tree-label:hover {
    background-color: #eee;
}
