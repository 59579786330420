@import "./colors";

$orange-colors--light: (
  "black": $orange--black,
  "white": $orange--white,
  "primary-50": $orange--primary-50,
  "primary-100": $orange--primary-100,
  "primary-200": $orange--primary-200,
  "primary-300": $orange--primary-300,
  "primary-400": $orange--primary-400,
  "primary-500": $orange--primary-500,
  "primary-600": $orange--primary-600,
  "primary-700": $orange--primary-700,
  "primary-800": $orange--primary-800,
  "primary-900": $orange--primary-900,
  "accent-50": $orange--accent-50,
  "accent-100": $orange--accent-100,
  "accent-200": $orange--accent-200,
  "accent-300": $orange--accent-300,
  "accent-400": $orange--accent-400,
  "accent-500": $orange--accent-500,
  "accent-600": $orange--accent-600,
  "accent-700": $orange--accent-700,
  "accent-800": $orange--accent-800,
  "accent-900": $orange--accent-900,
);

$orange-colors--dark: (
  "black": $orange-dark--black,
  "white": $orange-dark--white,
  "primary-50": $orange-dark--primary-50,
  "primary-100": $orange-dark--primary-100,
  "primary-200": $orange-dark--primary-200,
  "primary-300": $orange-dark--primary-300,
  "primary-400": $orange-dark--primary-400,
  "primary-500": $orange-dark--primary-500,
  "primary-600": $orange-dark--primary-600,
  "primary-700": $orange-dark--primary-700,
  "primary-800": $orange-dark--primary-800,
  "primary-900": $orange-dark--primary-900,
  "accent-50": $orange-dark--accent-50,
  "accent-100": $orange-dark--accent-100,
  "accent-200": $orange-dark--accent-200,
  "accent-300": $orange-dark--accent-300,
  "accent-400": $orange-dark--accent-400,
  "accent-500": $orange-dark--accent-500,
  "accent-600": $orange-dark--accent-600,
  "accent-700": $orange-dark--accent-700,
  "accent-800": $orange-dark--accent-800,
  "accent-900": $orange-dark--accent-900,
);

$orange-primary-light: (
  50: $orange--primary-50,
  100: $orange--primary-100,
  200: $orange--primary-200,
  300: $orange--primary-300,
  400: $orange--primary-400,
  500: $orange--primary-500,
  600: $orange--primary-600,
  700: $orange--primary-700,
  800: $orange--primary-800,
  900: $orange--primary-900,
  contrast: (
    50: $orange--black,
    100: $orange--black,
    200: $orange--black,
    300: $orange--black,
    400: $orange--white,
    500: $orange--white,
    600: $orange--white,
    700: $orange--white,
    800: $orange--white,
    900: $orange--white,
  ),
);

$orange-accent-light: (
  50: $orange--accent-50,
  100: $orange--accent-100,
  200: $orange--accent-200,
  300: $orange--accent-300,
  400: $orange--accent-400,
  500: $orange--accent-500,
  600: $orange--accent-600,
  700: $orange--accent-700,
  800: $orange--accent-800,
  900: $orange--accent-900,
  contrast: (
    50: $orange--black,
    100: $orange--black,
    200: $orange--black,
    300: $orange--black,
    400: $orange--white,
    500: $orange--white,
    600: $orange--white,
    700: $orange--white,
    800: $orange--white,
    900: $orange--white,
  ),
);

$orange-primary-dark: (
  50: $orange--primary-50,
  100: $orange--primary-100,
  200: $orange--primary-200,
  300: $orange--primary-300,
  400: $orange--primary-400,
  500: $orange--primary-500,
  600: $orange--primary-600,
  700: $orange--primary-700,
  800: $orange--primary-800,
  900: $orange--primary-900,
  contrast: (
    50: $orange--black,
    100: $orange--black,
    200: $orange--black,
    300: $orange--black,
    400: $orange--white,
    500: $orange--white,
    600: $orange--white,
    700: $orange--white,
    800: $orange--white,
    900: $orange--white,
  ),
);

$orange-accent-dark: (
  50: $orange--accent-50,
  100: $orange--accent-100,
  200: $orange--accent-200,
  300: $orange--accent-300,
  400: $orange--accent-400,
  500: $orange--accent-500,
  600: $orange--accent-600,
  700: $orange--accent-700,
  800: $orange--accent-800,
  900: $orange--accent-900,
  contrast: (
    50: $orange--black,
    100: $orange--black,
    200: $orange--black,
    300: $orange--black,
    400: $orange--white,
    500: $orange--white,
    600: $orange--white,
    700: $orange--white,
    800: $orange--white,
    900: $orange--white,
  ),
);
