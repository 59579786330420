.mat-mdc-list {
  .mat-mdc-list-item {
    &.selected {
      background-color: var(--primary-100);
    }

    &.new {
      background-color: var(--accent-50);
    }

    &.mdc-list-item--with-leading-icon .mdc-list-item__start {
      margin-inline-end: 16px;
    }

    &.cursor--pointer {
      cursor: pointer;
    }

    &.border-all {
      border-bottom-width: 1px;
      border-right-width: 1px;
      border-top-width: 0;
      border-left-width: 1px;
      border-color: #ddd;
      border-style: solid;

      &:first-child {
        border-top-width: 1px;
      }
    }
  }
}
