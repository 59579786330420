@use "@angular/material" as mat;
@import "./assets/stylesheets/index";
@include mat.core();

.itv-legacy-input {
  font-size: 12px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  background: transparent;
}
.itv-legacy-input:focus {
  border-width: 0 0 2px 0;
  border-color: rgba(26, 179, 147);
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  background: #000;
  opacity: 0.3 !important;
}

// Initially, we include default theme, and then will change only colors
@include mat.core-theme($default-theme);
@include mat.all-component-themes($default-theme);

// generate global css variables for all colors from theme
:root {
  @each $key, $color in $default-colors--light {
    --#{$key}: #{$color};
  }
}

.default-theme {
  @include mat.form-field-density(-1);
  @include mat.checkbox-density(-2);
  @include mat.icon-button-density(-2);
  @include mat.list-density(-2);
  &.light-mode {
    @include update-theme-colors($default-colors--light);
  }

  &.dark-mode {
    @include mat.all-component-colors($default-theme--dark);
    @include update-theme-colors($default-colors--dark);
  }
}

.orange-theme {
  &.dark-mode {
    @include mat.all-component-colors($orange-theme--dark);
    @include update-theme-colors($orange-colors--dark);
  }

  &.light-mode {
    @include mat.all-component-colors($orange-theme);
    @include update-theme-colors($orange-colors--light);
  }
}
.mat-drawer-container {
  background-color: #fff;
}

.mat-expansion-panel
  .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]),
.mat-expansion-panel
  .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]),
.mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
  background-color: #edf3f9;
}

// override bootstrap
html {
  input[type="checkbox"] {
    margin-top: 0;
  }
  .label,
  label {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: bold;
    color: var(--black);
  }

  & * {
    scrollbar-color: var(--primary-900) var(--primary-50);
    scrollbar-width: thin;
  }
}

.azure-map-expansion-panel .mat-expansion-panel-body {
  padding: 0px;
}
