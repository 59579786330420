:root {
    --base-font-size: 14px;
    --sidebar-width: 250px;
    --table-cell-height: 76px;
}
  
html, body {
    margin: 0;
    padding: 0;
    font-size: var(--base-font-size);
    background-color: #fff;
    position: static !important;
}

html, body {
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

body {
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #222;
    /*top: 0 !important;*/
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    line-height: 1.5em;
}

.md-button.md-default-theme.md-primary.md-fab, 
.md-button.md-primary.md-fab, 
.md-button.md-default-theme.md-primary.md-raised, 
.md-button.md-primary.md-raised {
    background-color: #24628F;
}

.md-button.md-default-theme.md-fab[disabled], 
.md-button.md-fab[disabled], 
.md-button.md-default-theme.md-raised[disabled], 
.md-button.md-raised[disabled] {
    background-color: #24628f33 !important;
}

.md-button.md-default-theme.md-primary.md-fab:not([disabled]).md-focused, 
.md-button.md-primary.md-fab:not([disabled]).md-focused, 
.md-button.md-default-theme.md-primary.md-fab:not([disabled]):hover, 
.md-button.md-primary.md-fab:not([disabled]):hover, 
.md-button.md-default-theme.md-primary.md-raised:not([disabled]).md-focused, 
.md-button.md-primary.md-raised:not([disabled]).md-focused, 
.md-button.md-default-theme.md-primary.md-raised:not([disabled]):hover, 
.md-button.md-primary.md-raised:not([disabled]):hover {
    background-color: #4683af;
}

md-toolbar .md-button.md-default-theme.md-fab[disabled],
md-toolbar .md-button.md-fab[disabled] {
    background-color: rgba(245,189,0,0.5) !important;
} 

.md-button {
    color: #000;
}
md-toolbar .md-button {
    color: #fff;
}

md-toolbar .md-button[disabled] {
    color: rgba(255, 255, 255, 0.5);
}

.md-button.md-fab {
    z-index: 0;
}

.groupButton {
    padding-right: 20px !important;
}

.printTable {
    width: 100%;
}

    .printTable td {
        border-top: 1px solid #ddd !important;
        padding: 3px 8px;
    }

    .printTable tr:last-child td {
        border-bottom: 1px solid #ddd;
    }

img:not(.cipo-base64) {
    border: 0;
    max-width: 100%;
    height: auto;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    /* This is better for small screens, once min() is better supported */
    /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
    grid-gap: 1rem;
    /* This is the standardized property now, but has slightly less support */
    /* gap: 1rem */
}
.gridContainerx2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}
.gridContainerx3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.gridContainer table, .gridContainerx2 table {
    width: 100%;
}

.gridItemCentered {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.highlighted {
    background: yellow;
}

#contractOverview .gridContainerx2 > div {
    padding: 0 8px;
}
.room1button {
    margin-right: 50px;
}

.room2button {
    margin-right: 100px;
    position: relative;
    padding-left: 50px;
}

.room2button .outerOrderWrapper {
    margin-top: 0;
    top: 0;
}

.visibleMenu .pageWrapper {
    margin-left: var(--sidebar-width);
}

.report {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 36px;
    top: 54px;
}

.visibleMenu .report {
    left: var(--sidebar-width);
}

.dashedBorder {
    border: 2px dashed gainsboro;
    padding: 5px !important;
}

.whiteDashedBorder {
    border: 2px dashed #ededed;
    padding: 5px !important;
}

.dashboardFilters {
    padding-top: 10px;
    margin-right: 0;
    width: 350px;
    position: fixed;
    background: white;
    z-index: 10;
    margin-top: 120px;
    margin-left: 100px;
    right: 10px;
    top: 0;
}

.printDashboardBtn {
    position: fixed;
    z-index: 20;
    top: 72px;
    right: 72px;
}

.filterDashboardBtn {
    position: fixed;
    z-index: 20;
    top: 72px;
    right: 20px;
}

.contractWrapper {
    float: right;
    margin-top: -7px;
}

.avatarWrapper {
    padding: 10px 0;
    float: left;
    width: 250px;
}

.wfState {
    display: inline-block;
    padding: 3px 7px;
    border-radius: 3px;
    text-align: center;
    width: 100%;
    border: 1px solid #bbb;
    line-height: 1.3em;
    font-size: 12px;
    overflow-wrap: break-word;
    word-break: break-word;
}

.instanceCol2 {
    margin-left: 280px;
    padding-top: 20px;
    position: relative;
}

label.required:before, .required .md-select-placeholder span:first-child:before,
gridster-item span[class*="placeholder"] .isRequired:before, p.required:before,
span.required:before, h3.required:before, th.required:before, div.required:before {
    content: "*";
    display: inline-block;
    color: red;
    margin-right: 2px;
}

.uneditableFormItem {
    border-bottom: 1px dashed rgba(215, 215, 215, 0.5);
    min-height: 24px;
    margin: 0 3px 28px;
    padding: 1px 0;
}

    .uneditableFormItem .gramm-problem, .uneditableFormItem .grammar-problem, .uneditableFormItem .wsc-grammar-problem {
        border-bottom: 0px;
    }

.uneditableFormLabel {
    font-size: 90%;
    font-weight: bold;
    margin: 0;
    margin-top: 23px;
    position: relative;
    top: -5px;
    padding-left: 3px;
    line-height: 1.07143;
    color: #000 !important;
}

.emailContent md-input-container, .emailEditForm md-input-container {
    margin-top: 0 !important;
}

.dashboardTopFilters .uneditableFormLabel {
    top: 0px;
}

.loginWrapper {
    background-size: cover;
    background-image: url('images/background.jpg');
    background-position: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 5;
}
.tenantsWrapper {
    background-image: url('images/background.jpg');
    background-position: center;
}

.loginCard {
    max-width: 500px;
    margin: 30px auto;
    text-align: left;
}

.authHeader {
    margin: 20px 0 30px;
}

.userInfoWrapper {
    padding: 4px;
    color: #fff;
}

    .userInfoWrapper img {
        margin: 15px 5px 15px 0;
        width: 40%;
        height: auto;
    }

a {
    cursor: pointer;
}

    a, a:hover, a:focus {
        color: var(--primary-900);
    }

.randomeTable {
    width: 100%;
}

    .randomeTable tr > * {
        padding: 3px 5px;
    }

.noErrorMessagesEver .errorContainer, .noErrorMessagesEver md-input-container .md-errors-spacer, .noErrorMessagesEver md-input-container .md-input-messages-animation {
    display: none;
}
.noErrorMessagesEver .uneditableFormItem {
    margin-bottom: 5px;
}

/* top formula */
.operatorWrapper {
    position: relative;
    display: inline-block;
}
.deleteOpOverlay {
    position: absolute;
    top: 10px;
    bottom: 0px;
    left: 10px;
    right: 0px;
    background: rgba(255,255,255,.6);
    z-index: 10;
    border-radius: 4px;
}
.opOverlay {
    background: rgba(245,188,0,.6);
}

.opOverlay:hover {
    background: rgba(245,188,0,.9);
}

.operatorHolder {
    border: 1px solid #ccc;
    display: inline-block;
    margin: 10px 0 0 10px;
    border-radius: 4px;
    min-height: 40px;
}

/*general purpose*/
.editable {
    z-index: 4;
}
.miniStatus {
    width: 100px;
    border-radius: 3px;
    padding: 1px 3px;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    position: relative;
    top: 3px;
    margin-right: 10px;
}
.descriptionTable th {
    padding: 3px !important;
    font-size: 10px !important;
}
.descriptionTable td {
    white-space: normal !important;
}

.descriptionTable {
    margin-bottom: 0 !important;
}
.minH50r {
    min-height: 50px;
    position: relative;
}
.mobileIcon {
}
h3:empty {
    display: none;
}

.alignToBtns {
    padding-top: 13px;
}

.formTable {
    table-layout: fixed;
    margin-bottom: 0;
}
.formTable tr {
    border-bottom: 0px solid #e0e0e0;
}

.gridContainer200Max {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1rem;
}

div[class^="cellx"] {
    float: left;
    min-height: 10px;
}

.cellx1 {
    width: 8.33%;
}

.cellx2 {
    width: 16.66%;
}

.cellx3 {
    width: 25%;
}

div.cellx3, div.cellx4 {
    float: left;
}

.cellx4 {
    width: 33.33%;
}

.cellx5 {
    width: 41.66%;
}

.cellx6 {
    width: 50%;
}

.cellx7 {
    width: 58.33%;
}

.cellx8 {
    width: 66.66%;
}

.cellx9 {
    width: 75%;
}

.cellx10 {
    width: 83.33%;
}

.cellx11 {
    width: 91.66%;
}

.cellx12 {
    width: 100%;
}

.rowx1 {
    height: calc(var(--table-cell-height) * 1) !important;
}

.rowx2 {
    height: calc(var(--table-cell-height) * 2) !important;
}

.rowx3 {
    height: calc(var(--table-cell-height) * 3) !important;
}

.rowx4 {
    height: calc(var(--table-cell-height) * 4) !important;
}

.rowx5 {
    height: calc(var(--table-cell-height) * 5) !important;
}

.rowx6 {
    height: calc(var(--table-cell-height) * 6) !important;
}

.rowx7 {
    height: calc(var(--table-cell-height) * 7) !important;
}

.rowx8 {
    height: calc(var(--table-cell-height) * 8) !important;
}

.rowx9 {
    height: calc(var(--table-cell-height) * 9) !important;
}

.rowx10 {
    height: calc(var(--table-cell-height) * 10) !important;
}

.rowx11 {
    height: calc(var(--table-cell-height) * 11) !important;
}

.rowx12 {
    height: calc(var(--table-cell-height) * 12) !important;
}

.rowDisbled {
    background-color: #e9e9e9;
    color: #5f5f5f;
    font-style: italic;
}

.commaElement:after {
    content: ', ';
    display: inline;
}

.commaElement:last-child:after {
    content: '';
    display: inline;
}

.testingMode {
    display: none;
}

.hasBorder {
    border-top: 1px solid #eeeeee;
}

.hasBackground {
    background: #edf3f9;
}

.hasYBackground {
    background: #ffd54f;
}

.hasBackgroundPale {
    background: #ededed;
}

.mainTable tr > .specialArrowCol {
    width: 1%;
    padding: 0 !important;
    min-width: 20px;
    max-width: 20px;
}

h1, .h1 {
    font-size: 2em;
    line-height: 1.15em;
}

h3, .h3 {
    font-size: 1.4em;
    line-height: 1.3em;
}

h4, .h4 {
    font-size: 110%;
    line-height: 1.3em;
}

h2, .h2 {
    font-size: 1.6em;
    line-height: 1.3em;
}

.draftRow {
    color: #2c2c2b;
    background: #f9f3ed;
}
.presetRow {
    color: #008080;
    background: #e4f6ef;
    font-weight: 400;
}

#sidebar-resizer {
    /* background-color: #666; */
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    cursor: e-resize;
    z-index: 10000;
    bottom: 0px;
    border-right: 3px dashed #e0e0e0;
}

.gridItemsWrapper.draftRow {
    background: transparent;
}

.borderTopWhite {
    border-top: 2px solid white;
}

.dropdown-menu li > a:hover {
    background: #edf3f9;
    color: #000;
    box-shadow: none;
}
.md-input-focused {
    z-index: 100 !important;
}
.zIndexGod {
    z-index: 100 !important;
}
md-backdrop.md-menu-backdrop {
    z-index: 101;
}
.md-open-menu-container {
    z-index: 102;
}
.middleVertical td {
    vertical-align: middle;
}

.noteBlock {
    margin: 0;
    padding: 0;
    color: #000;
    text-transform: capitalize;
    font-size: 90%;
    position: relative;
    font-weight: 400;
    line-height: 1.5em;
    display: block;
}
.fieldFormattingWrapper {
    display: inline-block;
    background: #f0f0f0;
    padding: 4px 8px;
    border: 1px solid #e0e0e0;
    color: #202020 !important;
    margin-right: -1px;
}
gridster-item .fieldFormattingWrapper, gridster-item .fieldRestrictionWrapper {
    padding: 0px 8px;
}
.fieldRestrictionWrapper {
    
    display: inline-block;
    background: #fff7dc;
    padding: 4px 8px;
    border: 1px solid #e0e0e0;
    color: #202020 !important;
}

.warning-msg {
    background-color: #ffd54f !important;
    position: relative;
}

    .warning-msg .loadingText {
        color: #040404;
    }

.warningWrapper {
    position: absolute;
    top: 0;
    right: 8px;
}

.hideComma:last-child span {
    display: none;
}
.halfVisible {
    opacity: .5;
}
.hoverableText {
    color: rgba(0,0,0,.4);
}
.hoverableText:hover {
    color: #edf3f9;
}
md-content.md-default-theme, md-content {
    background-color: #fff !important; 
}
.hoverable {
    cursor: pointer;
    position: relative;
    cursor: hand;
    padding: 1px 0;
}

    .hoverable:hover, .hoverable.active {
        background-color: #edf3f9;
    }

.daysWrapper {
    float: left;
    width: 14.28%;
    position: relative;
}

    .daysWrapper > div {
        margin: 1px;
        padding: 0 2px;
        border: 1px solid #dcdcdc;
    }

.activateError .inactiveError {
    border-color: rgb(221,44,0);
}

    .activateError .inactiveError .loadingText {
        color: rgb(221,44,0);
    }

.holiday {
    background-color: #ececec;
    color: #999;
}

.ellipsedText {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

span.ellipsedText {
    font-size: 90%;
}

.borderWrapper h4, .borderWrapper h3, .borderWrapper h2 {
    margin-top: 10px;
    margin-bottom: 0;
}

.collapsedParent .contentToHide {
    display: none;
}

.collapsedParent.expanded .contentToHide {
    display: block;
}

.expandIcon {
    position: absolute;
    right: 10px;
    top: 18px;
}

.expanded .expandIcon {
    top: 22px;
}

.pbWrapper {
    padding-bottom: 5px;
}

.permissionsWrapper {
    border: 1px solid #e0e0e0;
    margin: 0 1% 7px 0;
    padding: 3px 10px;
    border-radius: 4px;
}

    .permissionsWrapper.selectedPermission {
        border: 1px solid #fed965;
    }

    .permissionsWrapper.cellx6 {
        width: 49.5%;
    }

        .permissionsWrapper.cellx6:nth-child(2n) {
            margin: 0 0 7px 0;
        }



.indefiniteProgress md-progress-linear.md-default-theme .md-bar, .indefiniteProgress md-progress-linear .md-bar {
    background-color: gray;
}
.failedProgress md-progress-linear.md-default-theme .md-bar, .failedProgress md-progress-linear .md-bar {
    background-color: red;
}

.clearfix:after {
    content: "";
    display: block;
    clear: both;
}

.clearAll {
    clear: both;
}

.noMarkupList {
    list-style: none outside;
    margin: 0;
    padding: 0;
}

.inlineLis {
    display: inline-block;
}

.floatedLis {
    float: left;
}

.loadingText {
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
}

.basicWrapper {
    background: #eaeaea !important;
}

.dangerWrapper {
    background: #ffd54f !important;
    color: #040404 !important;
}

.importantItem {
    background: #b1b1b1;
    padding: 0px 4px;
    border-radius: 3px;
    color: white;
    min-width: 80%;
    display: inline-block;
}

.hoverable .importantItem:hover {
    color: #333;
    background: #edf3f9;
}

.infoText {
    background: #eaeaea;
    padding: 5px 8px;
}

.inlineManagerWrapper {
    bottom: 0;
    background: #fff;
    position: relative;
    overflow: hidden;
}
.attachmentRoot {
    clear: both;
    position: relative;
    
}
.tableAttachement .attachmentRoot {
    clear: none;
}
.attachmentWrapper {
    top: 0;
    position: fixed;
    left: 0;
    bottom: 130px;
    padding-top: 40px;
}

.filesWrapper {
    height: 130px;
    background: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 10px;
}

    .filesWrapper .filesInnerWrapper {
        border: 3px dashed #dddddd;
        height: 110px;
        padding: 10px;
    }

.innerManagerWrapper {
    border: 1px solid #e0e0e0;
    border-top: 0;
}

.attachmentWrapper .innerManagerWrapper {
    border: 0px solid #e0e0e0;
}

.innerManagerWrapper #mainTable th {
    border-top: 0;
}

.inlineManagerModal.inlineManagerModalShow {
    margin-top: 40px;
    left: 0;
}

.inlineManagerModal {
    position: absolute;
    top: 0px;
    height: 397px;
    left: 100%;
    width: 100%;
    transition: all .5s ease;
}

.inlineManagerModalInner {
    position: absolute;
    top: 0px;
    bottom: 0;
    background: white;
    max-width: 400px;
    right: 0;
    border: 1px solid #e0e0e0;
    padding: 15px;
    z-index: 5;
}

.inlineManagerWrapper h4 {
    background: #2e7eb8;
    margin: 0 !important;
    padding: 5px 10px 0;
    color: white;
    border-radius: 0;
}

.attachmentWrapper h4 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    padding-top: 10px;
    padding-right: 80px;
    white-space: nowrap;
    z-index: 200;
}

.inlineManagerWrapper h4 a {
    color: #fff;
}

.inlineManagerWrapper h4.breadcrumb > .active {
    color: rgba(255,255,255,.5);
}

.inlineManagerWrapper .actionBar {
    position: static;
    border-top: 1px solid #e0e0e0;
    margin-left: 0 !important;
}

.attachmentWrapper .actionBar {
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 1;
}
.attachmentWrapper .btnAttachmentDone {
    position: absolute; 
    right: 0; 
    top: 0; 
    margin: 2px;
    color: #fff;
}

.inlineManagerWrapper .tableView, .inlineManagerWrapper .gridView {
    padding-top: 0;
    height: 300px;
}

.attachmentWrapper .tableView {
    padding-top: 0;
    height: auto;
    margin-top: 61px;
}

.attachmentWrapper .innerManagerWrapper {
    height: 100%;
    position: relative;
}

div[class^="col-"] md-card {
    width: 100%;
}

.overlayed, .overlayedTransparent {
    position: relative;
    overflow: hidden;
}

.overlayedChild .scrollable > div, .overlayedChild .covered {
    position: relative;
}

    .overlayed:after, .overlayedTransparent:after, .overlayedChild .scrollable > div:after, .overlayedChild .covered:after {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 1;
    }

    .overlayedTransparent:after, .overlayedChild .scrollable > div:after, .overlayedChild .covered:after {
        background-color: rgba(255, 255, 255, 0);
    }

.propField .overlayed:after, .propField .loaderWrapper {
    background-color: rgba(225, 240, 244, 0.5);
}

.max-width-col {
    display: inline-block;
    max-width: 200px;
    width: 100%;
}


.col-2of3 {
    width: 66.66%;
    float: left;
}
.col-2of3_gap {
    width: 65.66%;
    float: left;
}

.col-1of3 {
    width: 33.33%;
    float: left;
    min-height: 3px;
}

.col-1of4 {
    width: 25%;
    float: left;
    min-height: 3px;
}
.col-1of4_gap {
    width: 24%;
    float: left;
    margin-right: 1%;
    min-height: 3px;
}
.col-3of4 {
    width: 75%;
    float: left;
    min-height: 3px;
}

.col-1of2_gap {
    width: 49% !important;
    float: left;
}

.col-1of2_gap:nth-child(2n) {
    float: right;
}

.col-1of2 {
    width: 50%;
    float: left;
}
.gap_right {
    margin-right: 1%;
}
.gap_left {
    margin-left: 1%;
}
.col-fullWidth {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.activeItem {
    background: #ffd54f;
}

.movedx1 {
    transform: translateX(-50%);
}

.bottomCard.moveInPlace {
    left: 0;
    right: 0;
}

.cardsWrapper {
    position: relative;
    transition: all .5s ease;
    z-index: 2;
}

    .cardsWrapper h3 {
        margin-top: 0;
        padding-top: 7px;
    }

.cardsx2 {
    width: 200%;
}

.cardsViewport {
    /*overflow: hidden;*/
    width: 100%;
}

.cardsWrapper md-card {
    margin: 8px .5%;
    clear: both;
    width: 99% !important;
}

.cardsx2 > .smallForm {
    width: 50%;
    display: inline-block;
    float: left;
    clear: none;
}

/*sortable*/
.listHandle {
    display: inline-block;
    vertical-align: middle;
    cursor: move;
}

.sortableList {
    margin: 0;
    padding: 0;
    list-style: none outside;
}
.tableLayout.sortableList {
    white-space: nowrap;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    padding-top: 6px;
}
.ui-sortable-placeholder {
    border: 1px dashed #ccc;
    visibility: visible !important;
    display: block;
    height: 32px !important;
}

.tableLayout .ui-sortable-item {
    min-width: 0px;
    max-width: 100%;
    display: inline-block;
}
.tableLayout .ui-sortable-placeholder {
    height: auto !important;
}
.ui-sortable-item {
	position: relative;
    min-width: 160px;
    max-width: 100%;
}
.ui-sortable-item .md-button.md-icon-button {
    font-size: 14px;
    height: 25px;
    width: 25px;
    line-height: 1px;
    padding: 0;
    min-height: 0;
}

    .ui-sortable-item .item {
        display: flex;
        border: 1px solid #dcdcdc;
        padding: 5px;
        margin: 1px;
        white-space: nowrap;
        height: 32px;
    }
.tableLayout .ui-sortable-item .item {
    border: 0px solid #dcdcdc;
    white-space: nowrap;
    border-right: 1px dotted #9e9e9e;
    min-height: 32px;
    height: auto;
}
.sortableFormWrapper {
    position: absolute;
    left: -10px;
    right: -10px;
    top: -10px;
    background: white;
    z-index: 5;
    padding: 10px 2px 0px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.sortableFormActions {
    position: absolute;
    right: 0;
    z-index: 3;
    top: 2px;
}

.sortableFormWrapper .md-button.md-icon-button {
    font-size: 14px;
    height: 25px;
    width: 25px;
    line-height: 1px;
    padding: 0;
    min-height: 0;
}

/*module instance*/
.instanceDetails h4 {
    margin-top: 0;
    font-size: 1.1em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.instanceDetails .noteBlock {
    top: 0;
}
/* dashboard */
.overviewWrapper md-content {
    min-height: 0;
}

.projectState {
    font-weight: 700;
    border-bottom: 1px solid #f5f5f5;
    min-height: 60px;
    width: 100%;
}

.projectsNo {
    font-size: min(6.5vw, 36px);
    float: left;
    width: 50%;
    line-height: 1em;
}

.projectsDetails {
    float: left;
    width: 50%;
    text-align: right;
}

.highcharts-container {
    width: 100% !important;
}

.gridLike {
    padding: 4px;
    border: 1px solid #ddd;
    display: inline-block;
    margin: 1px;
    line-height: 0;
}
.gridLike:hover {
    background-color: #edf3f9;
}

.gridSimulation {
    padding: 15px 10px 10px;
    margin: 5px;
    position: relative;
    border-radius: 3px;
}

    .gridSimulation:before {
        content: "";
        position: absolute;
        left: 3px;
        top: 3px;
        bottom: 3px;
        right: 3px;
        border: 1px solid #f5f5f5;
        pointer-events: none;
        border-radius: 3px;
    }

.dashboardCounts {
    display: inline-block;
    text-align: center;
    min-width: 25%;
}

    .dashboardCounts .noteBlock {
        font-size: 10px;
    }

.dashboardIcon {
    font-size: 38px !important;
    float: left;
    color: #a9a9a9;
    min-width: 38px;
    margin-right: 10px;
    text-align: center;
    margin-top: -2px;
}

.warningBox, .closedContract, .archivedContract, .successBox, .dangerBorderBox, .warningBorderBox, .successBorderBox, .neutralBorderBox, .neutralBox, .paleNeutralBoderBox {
    padding: 3px 5px;
    min-width: 30px;
    text-align: center;
    border-radius: 3px;
    font-weight: 700;
    display: inline-block;
}

.warningBox, .closedContract, .archivedContract, .successBox, .dangerBorderBox, .successBorderBox, .neutralBorderBox, .neutralBox {
    color: #2c2c2c;
}

.neutralBox {
    background-color: #ffffe8;
}

.neutralBorderBox {
    background-color: #edf3f9;
    color: #333;
}

.paleNeutralBox {
    background-color: #eaeaea;
}
.paleNeutralBoderBox, .paleNeutralBoder {
    border: 1px solid #eaeaea;
}
.dangerBox {
    background-color: #dd2c00;
    color: #fff;
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 30px;
    text-align: center;
    border-radius: 3px;
    font-weight: 700;
    display: inline-block;
}

.warningBox, .warningAnything {
    background-color: #ffc10a;
}

.closedContract {
    background-color: #b05470;
    color: #fff;
    display: inline;
    margin-right: 7px;
    height: 24px;
    line-height: 18px;
    min-width: 56px;
}

.archivedContract {
    background-color: #1c183e;
    color: #fff;
    display: inline;
    margin-right: 7px;
    height: 24px;
    line-height: 18px;
    min-width: 56px;
}

.paddedWarning.warningAnything {
    padding: 5px 0;
    margin: 5px 0;
}

.warningAnything .room2button {
    padding-left: 0;
}

.positiveNumberBox {
    background-color: #fdfde5;
}

.negativeNumberBox {
    background-color: #f9edf3;
}

.neutralNumberBox {
    background-color: #ffffe8;
}

.successBox {
    background-color: #4daf50;
}

.successText {
    color: #4daf50;
}

.systemBox {
    background-color: #d1e1ed;
    border: 0 !important;
}

.dangerBorderBox {
    border: 1px solid #dd2c00;
}

.warningBorderBox, .warningBorder {
    border: 1px solid #ffc10a !important;
}
.warningBorder {
    z-index: 1;
}
.successBorderBox {
    border: 1px solid #4daf50;
}


.dangerText, .dangerBorderBox {
    color: #dd2c00 !important;
}

.warningText {
    color: #ffc10a !important;
}

.successText, .successBorderBox {
    color: #4daf50 !important;
}

.blueText, .blueText:before {
    color: #6a7aca !important;
}

.blueBorder {
    border: 1px solid #6a7aca;
}
.blueBackground {
    background: rgba(106,122,202,.3);
}

.neutralText {
    color: #898989;
}
.mediumNeutralText {
    color: #bfbfbf;
}
.paleNeutralText {
    color: #e0e0e0;
}

.inactiveText {
    color: rgba(0, 0, 0, 0.4);
}

.textButton {
    opacity: .8; 
}
.textButton:hover {
    opacity: 1; 
}

.projectsWrapper table {
    margin: 0px 0;
    width: 100%;
}

.projectsWrapper td {
    padding: 2px 0 !important;
    text-align: right;
}

    .projectsWrapper td:first-child {
        text-align: left;
    }

.formRow:after {
    content: "";
    display: block;
    clear: both;
}

.mobileForm {
    position: fixed;
    top: 65px;
    bottom: 0;
    left: -16px;
    right: -16px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1010;
    padding: 15px 5% 0;
}

    .mobileForm ::placeholder, .mobileForm input, .mobileForm button {
        color: white !important;
        border-color: rgba(255, 255, 255, 0.7) !important;
        font-size: 22px !important;
    }

    .mobileForm .searchForm {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        flex-direction: row;
    }

    .mobileForm md-input-container {
        width: 100%;
    }

.mobileBtn {
    display: none;
}
/* tree overwrites*/
.angular-ui-tree-handle {
    font-weight: unset;
}

.noIcon li .angular-ui-tree-handle {
    padding-left: 10px;
    padding-right: 130px;
}

.noIcon .formWrapper {
    min-height: 0;
}

.noIcon.noActions li .angular-ui-tree-handle {
    padding-right: 10px;
}

.fixedHeightTree .angular-ui-tree {
    max-height: 250px;
    overflow: auto;
}

.angular-ui-tree .noteBlock {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.panel {
    box-shadow: none;
}
.hasBottomFormulas {
    width: 60px;
}

/*bootstrap overwrites*/
hr {
    border-top: 1px solid rgba(0,0,0,0.1);
}

.form-group.is-focused label {
    color: rgb(0,131,143);
}

.form-group.is-focused .form-control {
    background-image: linear-gradient(rgb(0,131,143), rgb(0,131,143)), linear-gradient(#D2D2D2, #D2D2D2);
    background-size: 100% 2px, 100% 1px;
}

.modal-content .modal-header {
    padding: 0;
}

label.control-label {
    color: rgba(0,0,0,0.8);
}
/*md overwrites*/

.rz-table td md-checkbox:last-of-type {
    position: relative;
    left: 3px;
}

.test1 md-checkbox.md-default-theme[disabled]:not(.md-checked) .md-icon, .test1 md-checkbox[disabled]:not(.md-checked) .md-icon {
    border-color: rgba(0,0,0,0.25);
}


.globalFiltersTabs {
    flex: 1 1 auto;
    margin-left: 16px;
}

.globalFiltersTabs md-tabs md-tabs-wrapper {
    border-width: 0 !important;
}

.actionBar .md-tab {
    padding: 0;
}
.entityInstanceTabs md-tabs {
    padding-bottom: 40px;
}
.md-select-value {
    min-height: 25px;
}
    .fullHeight {
    height: 100%;
}
md-option .md-text {
    width: 100%;
}
md-option .noteBlock {
    border: 1px solid #ccc;
    padding: 2px 5px;
    margin-left: 5px;;
    display: inline;
}
md-input-container.md-input-has-value input:not(:focus).overlayedNumberInput {
    caret-color: rgba(0,0,0,0.87);
    letter-spacing: 0.5px;
    color: transparent !important;
}
.numberOverlay {
    position: absolute;
    top: 9px;
    left: 4px;
    overflow: hidden;
    pointer-events: none;
    right: 13px;
}

md-input-container.md-input-has-value input:not(:focus) {
    color: inherit !important;
}
.md-raised:not([disabled]) {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

md-input-container.md-input-focused label:not(.md-no-float), md-input-container.md-input-has-placeholder label:not(.md-no-float), md-input-container.md-input-has-value label:not(.md-no-float) {
    color: #000;
    font-size: 90%;
    font-weight: bold;
    -webkit-transform: translate3d(0, 6px, 0) scale(1);
    transform: translate3d(0, 6px, 0) scale(1);
}

md-backdrop.md-select-backdrop {
    z-index: 85;
}

.md-primary.md-raised {
    background-color: #24628F;
    color: #fff !important;
}
.control-label {
    text-transform: none !important;
}
.cipoModalOuter {
    position: fixed;
    z-index: 82;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    bottom: 0;
    padding: 0 1%;
}
.cipoModalInner {
    background: #fafafa;
    max-width: 1024px;
    border-radius: 4px;
    padding: 55px 16px 65px;
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    width: 100%;
    margin: 10% auto 0;
    height: 80%;
    position: relative;
}
.cipoModalInner.smallerModal {
    max-width: 995px;
    margin: 15% auto 0;
    height: 73%;
    width: 98%;
}
.cipoModalHeader {
    position: absolute;
    top: 0;
    left: 16px;
    right: 16px;
}
.cipoModalFooter {
    position: absolute;
    bottom: 6px;
    left: 16px;
    right: 16px;
    text-align: right;
}
.cipoModalFooter .md-button {
    margin-right: 0;
}

md-select[disabled] .md-select-value {
    padding-bottom: 2px;
}

.contractList md-option {
    max-width: 500px;
}

.md-color-picker-input {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.hasClearBtn, .hasClearBtn label {
    padding-right: 40px !important;
}

md-checkbox .md-label {
    width: 100%;
    padding-left: 30px;
    margin-left: 0;
    color: #000;
    font-size: 90%;
    font-weight: bold;
}

md-dialog.md-transition-in, md-dialog.md-transition-out {
    transition: 0s;
}

.md-color-picker-preview md-input-container.md-input-focused label:not(.md-no-float),
.md-color-picker-preview md-input-container.md-input-has-placeholder label:not(.md-no-float),
.md-color-picker-preview md-input-container.md-input-has-value label:not(.md-no-float) {
    margin: 0;
}



.searchTerm .md-errors-spacer, .md-chip-input-container .md-errors-spacer {
    display: none;
}

.md-dialog-container {
    height: 100% !important;
}

.md-contact-chips .md-chips md-chip {
    margin: 2px 8px 0 0;
}

.md-contact-chips .md-chips .md-chip-input-container {
    width: 100%;
    margin-right: 0;
}

.md-chips .md-chip-input-container {
    margin-top: 4px !important;
}

.md-dialog-is-showing, md-dialog-container, .test1 {
    top: 0 !important;
}
.md-dialog-is-showing {
    overflow: hidden;
}

label.control-label:empty {
    display: none;
}

label.itv-form-label-noneditable {
    font-size: 10px;
    margin: 0;
    position: relative;
    top: -3px;
}

.itv-form-static-noneditable {
    padding-top: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.hasError {
    color: rgb(221,44,0) !important;
}

    .hasError md-chips .md-chips {
        box-shadow: 0 1px rgb(221,44,0);
    }

    .hasError input::placeholder {
        color: rgb(221,44,0) !important;
        opacity: 1;
    }

.form-control-static {
    padding-bottom: 15px;
}

.formWrapper {
    min-height: 100px;
}
.formWrapper > p {
    padding-top: 15px;
}

.table .table {
    background: transparent;
}

.noMinHeightChildren .formWrapper {
    min-height: 26px;
}
.noMinHeightChildren .sortableFormActions i.mdi, .noMinHeightChildren label, .noMinHeightChildren md-checkbox * {
    min-height: 0 !important;
}
.editableFormLabel {
    color: #000;
    font-size: 90%;
    font-weight: bold;
}
.rowInfo .uneditableFormLabel {
    margin-top: 5px;
}
.rowInfo {
    background: #f7f9fc;
    margin-bottom: 2px;
    padding: 3px 5px 5px;
}
.multipleValueFields {
    margin-top: 0;
}
.noMinHeightChildren {
    clear: both;
}

.borderWrapper .formWrapper {
    min-height: 50px;
}

.md-blue-button {
    background-color: #edf3f9 !important;
}

md-dialog.hasTabs {
    width: 70%;
    max-width: 900px;
}

md-dialog md-dialog-content, md-tab-content {
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100%;
}

md-dialog md-dialog-content, .hiddenTabs md-tab-content {
    margin: 0px;
}

md-dialog.fullScreenModal {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    background: #fff;
    overflow-x: hidden;
}

md-dialog.smallerModal {
    width: 95%;
    max-width: 95%;
    height: 95%;
    max-height: 95%;
    background: #fff;
}

md-dialog.fullScreenModal md-content {
    padding-bottom: 60px;
    background-color: transparent;
}

    md-dialog.fullScreenModal md-content md-content {
        padding-bottom: 16px;
        background-color: transparent;
    }

.fullScreenModal md-dialog-actions {
    position: relative;
    bottom: 0;
    right: 0;
    width: 100%;
    background: #fff;
}

.fullScreenModal md-dialog-content, .fullScreenModal md-dialog-content md-content {
    height: 100%
}

#office_frame {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    border: none;
    display: block;
    background: #fff;
    z-index: 100;
}

.instanceDetails, .instanceRightContent {
    height: 100%;
    overflow: auto;
}

    .instanceRightContent md-card {
        margin: 3px 5px 8px 2px;
        clear: both;
    }

    .instanceRightContent > .pull-right {
        position: fixed;
        right: 3%;
        _right: 5%;
        z-index: 10;
        margin-top: 10px;
    }

.permanentNotEditable .uneditableFormItem {
    margin-bottom: 5px;
    white-space: normal;
}

.alternate-rows:nth-child(2n), .alternateRows tr:nth-child(2n) {
    background: #f5f5f5;
}
.alternateRows.table tr td {
    padding: 10px 5px 0 !important;
}
.alternate-rows {
    padding: 5px 0;
}

.form-control-static > *:last-child {
    margin-bottom: 0;
}
.form-control-static > hr {
    margin-bottom: 0;
    margin-top: 0;
    position: relative;
    top: 20px;
}

.permanentNotEditable .formWrapper {
    min-height: 0;
}

.permanentNotEditable label.itv-form-label-noneditable {
    font-size: 11px;
    margin: 16px  0 0 0;
    position: relative;
    top: 1px;
}

.md-contact-suggestion .md-contact-name, .md-contact-suggestion .md-contact-email {
    display: block;
}

.md-contact-suggestion .md-contact-name {
    margin-left: 0;
    width: 100%;
}

.md-autocomplete-suggestions li {
    font-size: var(--base-font-size);
    overflow: hidden;
    padding: 8px 10px;
    line-height: normal;
    height: auto;
}

.md-contact-suggestion, .md-contact-chips .md-chips md-chip .md-contact-name, .md-chips .md-chip-input-container md-autocomplete, .md-chips .md-chip-input-container md-autocomplete-wrap {
    height: auto;
}

.md-chips {
    font-size: var(--base-font-size);
    padding: 2px 0 3px 3px;
}

    .md-chips md-chip .md-chip-remove md-icon {
        min-height: 0;
        min-width: 0;
    }

    .md-chips .md-chip-input-container input {
        height: 22px;
        line-height: 22px;
    }

md-chips.md-default-theme md-chip, md-chips md-chip {
    border: 1px solid rgb(224,224,224);
    background: transparent;
    color: rgb(66,66,66);
}

.md-chips md-chip {
    border-radius: 10px;
}

.md-chips md-chip, .md-chips md-chip .md-chip-remove-container {
        line-height: 20px;
        top: -4px;
}

.md-chips md-chip .md-chip-remove {
            width: 20px;
            height: 20px;
}

.md-chips md-chip {
    height: 22px;
}

md-card md-card-content .md-media-xl {
    height: auto;
}

md-card .md-media-xl {
    width: 240px;
}

md-radio-button {
    margin-bottom: 8px;
}

md-tabs.md-default-theme .md-tab.md-active, 
md-tabs .md-tab.md-active, md-tabs.md-default-theme .md-tab.md-active md-icon, md-tabs .md-tab.md-active md-icon, md-tabs.md-default-theme .md-tab.md-focused, md-tabs .md-tab.md-focused, md-tabs.md-default-theme .md-tab.md-focused md-icon, md-tabs .md-tab.md-focused md-icon {
    color: #24628F;
}

md-checkbox {
    max-width: 100%;
    margin-bottom: 0;
}

md-input-container {
    margin: 11px 0 3px 0;
}

.filterInput {
    margin: 11px 0 0;
}

.checkboxCollection .md-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.checkboxCollection md-checkbox {
    margin-bottom: 5px;
}

.md-toolbar-tools h3 {
    width: 100%;
    font-size: .8em;
    font-weight: bolder;
    color: #fff;
}

.disabledTabs md-dialog-content md-tabs:not(.secondaryTabs) > md-tabs-wrapper::after, .disabledSecondaryTabs md-tabs.secondaryTabs > md-tabs-wrapper::after,
.disabledTopTabs md-dialog-content md-tabs:not(.secondaryTabs) > md-tabs-wrapper::after {
    content: "";
    display: block;
    position: absolute;
    background: rgba(248, 248, 248, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

md-content {
    min-height: 300px;
}

.md-button.md-fab.md-mini {
    padding: 0;
    margin: 0;
}

.checkbox label, .radio label, label {
    color: #24628F;
    font-size: var(--base-font-size);
}

md-card.smallForm md-card-title {
    padding: 16px 16px 0px;
}

md-card.smallForm md-card-title + md-card-content {
    padding-bottom: 0;
}

div[class^="col"] md-input-container {
    width: 100%;
}

table md-input-container:not(.managerSearch) {
    vertical-align: top;
    width: 100%;
}

md-input-container .md-input-messages-animation {
    white-space: normal;
}

.md-button.md-icon-button {
    font-size: 20px;
    margin: 0;
    min-width: 40px;
}

md-dialog {
    min-width: 380px;
}

    md-dialog.md-color-picker-dialog {
        min-width: 0;
    }

.rz-table md-checkbox .md-label {
    display: none;
}

.rz-table md-checkbox {
    margin-top: 5px;
}

label.control-label {
    color: #000;
    font-size: 90%;
    font-weight: bold;
}

md-input-container label.control-label {
    text-align: left !important;
    line-height: unset;
    z-index: 0 !important;
}
.md-select-value.md-select-placeholder {
    z-index: 0 !important;
}
.form-horizontal md-input-container {
    width: 100%;
}

i.mdi {
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
}

md-menu-content i.mdi {
    float: right;
}

md-menu-item > .md-button, .md-button {
    font-size: var(--base-font-size);
    overflow: hidden;
    max-width: 300px;
    text-overflow: ellipsis;
}

.md-icon-button {
    text-overflow: unset !important;
}

.md-button {
    text-transform: none;
    border-radius: 4px;
    padding: 0 12px;
    vertical-align: middle;
}

md-menu-item > .md-button {
    color: #757575;
}

md-menu-item {
    height: 42px;
    min-height: 42px;
}
/*temp*/


.dropdown-menu li > a {
    padding: 3px;
}

.modal-backdrop.in {
    display: none;
}

.modal.in {
    background: rgba(0,0,0,.4);
}

.navbar-right {
    display: none;
}

.contractMenuSelect .md-select-value.md-select-placeholder, .contractMenuSelect md-select {
    color: rgba(255,255,255,0.74) !important;
}

.contractMenuSelect md-input-container {
    text-align: left;
    width: 100%;
}

.contractMenuSelect md-select .md-text {
    color: rgba(255,255,255,0.74) !important;
}

.contractMenuSelect md-select.md-default-theme .md-select-value, .contractMenuSelect md-select .md-select-value {
    border-bottom-color: rgba(255,255,255,0.3) !important;
}

.contractMenuSelect md-input-container.md-default-theme:not(.md-input-invalid).md-input-has-value label, .contractMenuSelect md-input-container:not(.md-input-invalid).md-input-has-value label, .contractMenuSelect md-input-container.md-input-focused label, .contractMenuSelect md-input-container .md-select-placeholder, .contractMenuSelect md-select.md-default-theme .md-select-icon, .contractMenuSelect md-select .md-select-icon {
    color: rgba(255,255,255,0.54) !important;
}
/* containers */
.periodWrapper {
    
    padding: 0 10px;
}
.periodStateWrapper {
    width: 150px;
    text-align: center;
}
.periodsMenuItem {
    border: 1px solid #ccc;
    min-height: 30px;
    height: auto;
    margin: 2px 0;
}
.currentPeriodWrapper {
    border: 1px solid #ffc10a !important;
}
.pageWrapper {
    position: relative;
    min-height: 100%;
    padding-top: 55px;
    padding-bottom: 0;
}

.pageWrapper::after {
    content: "";
    display: block;
    clear: both;
}

.pageHeading {
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    padding: 16px 20px 8px 20px;
}

.pageHeading .mdi {
    color: #868686;
}

.pageHeading .headingIcon {
    float: left;
    font-size: 2.2em;
    margin-top: -6px;
    margin-right: 8px;
    min-width: 36px;
    min-height: 36px;
}

.contentWrapper {
    margin: 0 16px;
    padding: 0 0 55px 0;
    height: calc(100vh - 55px);
}

.contentOuterWrapper {
    flex: auto;
    overflow: auto;
    height: 100%;
}

.mainView {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.iframeContainer {
    width: calc(100% - 8px);
    height: calc(100% + 8px);
    border: none;
    margin-top: 4px;
    margin-left: 4px;
}

.headerLinks {
    margin-right: 0;
    padding: 3px 10px;
    display: flex;
}

.headerLinks .md-button {
    margin: 0;
}

.index_view_container {
    height: 100%; 
    min-height: 100%; 
    position: relative;
}

#index_view {
    height: 100%;
    overflow: auto;
}

.topContractButton, .topContractButton:disabled {
    max-width: 600px;
    background: #fff;
    color: #000;
    display: flex;
}
.topContractButton .topContractButtonText {
    max-width: 550px; 
    float: left;
    margin-right: 5px;
    font-size: var(--base-font-size);
}
@media screen and (max-width: 1150px) {
    .visibleMenu .topContractButton {
        max-width: 400px;
    }
    .visibleMenu .topContractButton .topContractButtonText {
        max-width: 350px; 
    }
}
@media screen and (max-width: 1050px) {
    .visibleMenu .topContractButton {
        max-width: 300px;
    }
    .visibleMenu .topContractButton .topContractButtonText {
        max-width: 250px; 
    }
}
@media screen and (max-width: 900px) {
    .visibleMenu .topContractButton {
        max-width: 200px;
    }
    .visibleMenu .topContractButton .topContractButtonText {
        max-width: 150px; 
    }
    .topContractButton {
        max-width: 300px;
    }
    .topContractButton .topContractButtonText {
        max-width: 250px; 
    }
}
@media screen and (max-width: 750px) {
    .visibleMenu .topContractButton {
        max-width: 150px;
    }
    .visibleMenu .topContractButton .topContractButtonText {
        max-width: 100px; 
    }
    .topContractButton {
        max-width: 200px;
    }
    .topContractButton .topContractButtonText {
        max-width: 150px; 
    }
}
@media screen and (max-width: 630px) {
    .navProfileWrapper .tenantLogo,
    .navProfileWrapper .tenantNoLogo {
        display: none !important;
    }
    .navProfileWrapper .userIcon,
    .navProfileWrapper .userImage {
        margin-left: 0 !important; 
    }
}

.navigationWrapper {
    height: 55px;
    position: relative;
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.16), 0 0px 5px 0 rgba(0, 0, 0, 0.26);
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 15;
}

.navigationInnerWrapper {
    margin: 0 18px;
    padding: 5px 0 0 0;
    position: relative;
    height: 100%;
    text-align: center;
    color: #767f86;
}

/* Loading animation for img tags on pdfMarkup*/
.loading {
/*    box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);*/
    position: relative;
    overflow: hidden;
}

.loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: loading 1s cubic-bezier(0.4, 0.0, 0.2, 1);
}

@keyframes loading {
    from {
        left: -150px;
    }

    to {
        left: 100%;
    }
}
/* End */

.notificationsNo {
    position: absolute;
    font-size: 11px;
    top: 2px;
    right: 2px;
    background: #2e7eb9;
    line-height: 1.2em;
    border-radius: 50%;
    padding: 3px;
    min-width: 20px;
    color: #fff;
}

.pdf-markup-grid-items-wrapper {
    width: 25%;
    float: left;
}

.pdf-markup-button-container {
    text-align: center;
    padding-top: 10px;
}

.pdf-markup-button {
    position: relative;
    margin-right: 10%;
    margin-left: 10%;
}

.pdf-markup-button-count {
    position: absolute;
    font-size: 11px;
    bottom: 10px;
    left: 15px;
    background: #2e7eb9;
    line-height: 1.2em;
    border-radius: 50%;
    padding: 3px;
    min-width: 20px;
    color: #fff;
    background-color: #54b095;
}

.pdf-markup-connected-user-btn {
    overflow: visible;
}

.pdf-markup-connected-user-count {
    position: absolute;
    font-size: 11px;
    bottom: 57%;
    left: 59%;
    background: #2e7eb9;
    line-height: 1.2em;
    border-radius: 50%;
    padding: 3px;
    min-width: 20px;
    color: #fff;
    background-color: #54b095;
}

.pdf-markup-preview-container {
    border-top: 2px solid #cad1dc;
    border-bottom: 2px solid #cad1dc;
    height: 225px;
    width: 100%;
    margin: 10px auto;
    padding: 10px 0;
}

.pdf-markup-preview-container img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
}

.pspdfkit-toolbar-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    padding: 5px 0;
}

.pspdfkit-toolbar-versions {
    margin-left: 5px;
}

.pspdfkit-spinner {
    margin: 0 auto;
    margin-top: 10px;
}	

.pspdfkit-click-message {
    height: 30px;
    position: absolute;
    bottom: 0%;
    width: 150px;
    color: black;
    background-color: rgb(245,189,0);
    /* opacity: 0.8; */
    text-align: center;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    border-radius: 5px 5px 0 0;
    font-size: 16px;
}
.pspdfkit-click-message p {
    margin-top: 4px;
}

.navigationInnerWrapper .md-button.md-icon-button {
    overflow: visible;
}

.stuff::after {
    position: fixed;
    display: block;
    top: 120px;
    bottom: 70px;
    content: "";
    background: white;
    left: 16px;
    right: 16px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.contentInnerWrapper {
    height: 100%;
}

.ibox-content {
    height: 100%;
}

/* top bar */
.navWrapper {
    min-height: 100%;
    position: relative;
}

.navigationInnerWrapper .logoWrapper {
    position: absolute;
    left: 35px;
    top: 10px;
}

.logoWrapper {
    font-size: 32px;
}

.navigationInnerWrapper .contractsHolder strong {
    font-size: 1.5em;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.contractsHolder {
    float: left;
    max-width: 60%;
}

.navWrapper .contractsHolder {
    float: none;
    width: 100%;
    max-width: 100%;
}

.navigationInnerWrapper .betaWrapper {
    color: white;
}

.betaWrapper {
    color: #2e7eb8;
    position: absolute;
    bottom: -9px;
    right: 9px;
    font-size: 14px;
    font-weight: 700;
    display: none;
}

.clientLogo {
    max-height: 100%;
    width: auto;
}

.headerItems {
    display: flex;
}

.navProfileWrapper {
    height: 45px;
    margin: 0;
    background: #fff;
    padding: 3px 10px;
    border-radius: 4px;
}

.navProfileWrapper .tenantLogo,
.navProfileWrapper .tenantNoLogo {
    max-height: 40px;
}
.navProfileWrapper .userIcon {
    font-size:36px; 
    margin-left: 5px; 
    vertical-align: middle
}
.navProfileWrapper .userImage {
    border-radius: 50%;
    width: 40px; 
    margin-left: 5px;
}

/* scroll elements*/
.scrollable {
    overflow-y: auto;
    overflow-x: hidden;
}

.horizontalScroll {
    overflow-x: auto;
    overflow-y: visible;
}

/* menu */
.md-button.md-icon-button.menuToggleButton {
    display: block;
    position: absolute;
    left: -11px;
    top: 50%;
    margin-top: -21px;
    font-size: 24px;
    z-index: 2;
}
.menuSearchWrapper {
    text-align: center;
    background: rgb(233, 236, 237);
    color: #000;
    padding: 0;
    height: 30px;
    position: relative;
    max-width: var(--sidebar-width);
    box-shadow: 2px 0px 10px 0 rgba(0, 0, 0, 0.16), 2px 0px 5px 0 rgba(0, 0, 0, 0.26);
}
.noItemsWrapper {
    color: #ffffff75;
    font-size: 11px;
    padding: 10px 0 0 10px;
    line-height: 1.4em;
}
.menuSearchWrapper input {
    width: 100%;
    background: transparent;
    font-size: 11px;
    height: 30px;
}
.menuSearchWrapper .minibutton {
    position: absolute;
    right: 0;
    top: 0;
}
.isContextuallyShown {
    opacity: .4;
}
.mobileMenuButton {
    display: none !important;
}

.visibleMenu .md-button.md-icon-button.menuToggleButton:not(.mobileMenuButton) {
    left: -24px;
    background: hsla(195, 10%, 83%, 1);
    border-radius: 0 50% 50% 0;
}

.visibleMenu .navigationInnerWrapper .logoWrapper {
    left: 22px;
}

.md-button.md-icon-button.menuToggleButton i.mdi {
    font-size: 24px;
}

.menuWrapper {
    position: fixed;
    transition: background .3s ease;
    left: -100%;
    width: 100%;
    height: 100%;
    top: 0;
}

.showCollapsedMenu {
    left: 0;
    /*background: rgba(0,0,0,0.6);*/
    z-index: 50;
    width: var(--sidebar-width);
}

.contractMenuSelect {
    width: 100%;
    text-align: left !important;
}

    .contractMenuSelect .control-label.itv-form-label.itv-form-editable-label {
        color: rgba(255, 255, 255, 0.8)
    }

.fa.arrow {
    float: right;
}


.projectState .fa.arrow {
    padding: 0 12px;
    border-radius: 50%;
    margin-top: -7px;
    font-size: 40px;
    color: rgba(0, 0, 0, 0.4);
}

.fa.arrow:before {
    content: "\f104";
}

.fa.forcemenuarrowdown:before, .expanded .fa.arrow:before {
    content: "\f107";
}

.projectState .fa.forcemenuarrowdown {
    padding: 0 7px;
}

/* actions */
.searchForm {
    margin-left: 6px;
}

.searchForm md-input-container {
   margin: 0;
}

.searchForm md-input-container .md-errors-spacer,
.userAssignmanetForm md-input-container .md-errors-spacer {
   display: none;
}

.confCard {
    background-color: #fcfdfe;
}

.actionsWrapper {
    display: inline-block;
}

.actionBar {
    background: #fff;
    padding: 0 16px;
}

.actionBar form {
    min-height: 60px;
    padding-top: 5px;
}

.actionBar .md-button, .toggleSelectAll {
    margin-top: 5px !important;
}

.underTabs.actionBar, .fileManagerMenu.actionBar, .underTabsActions .actionBar {
    top: 183px;
}

md-dialog-content .fileManagerMenu.actionBar {
    top: 104px;
}

.correspondenceMenu.actionBar {
    top: 85px;
}

.inline-manager-wbs {
    border: 1px solid transparent;
    border-color: rgba(0,0,0,0) rgba(0,0,0,0.12) rgba(0,0,0,0) rgba(0,0,0,0.12);
}

.inline-manager-category {
    border: 1px solid transparent;
    border-color: rgba(0,0,0,0) rgba(0,0,0,0.12) rgba(0,0,0,0.12) rgba(0,0,0,0.12);
}

.inlineManager .actionBar {
    position: static;
    margin-bottom: -1px;
}

.inlineManager .tableView, .inlineManager .gridView {
    padding-top: 0;
}

.emailTable {
    margin-top: 30px;
}

.actionBar:after {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
}
.correspondenceMenu.actionBar:after {
    border:0;
}

.tableView, .gridView {
    min-height: 200px;
    overflow: auto;
}

.correspondenceGrid.gridView {
    padding-top: 57px;
}

.noActionBar {
    padding-top: 0;
}

.contentInnerWrapper > md-tabs {
    height: 49px;
}

.tenantSettings md-tabs,
.tenantSettings md-tabs-content-wrapper,
.tenantSettings md-tabs-content-wrapper md-tab-content {
    overflow: inherit;
    background-color: var(--white);
}

.contentInnerWrapper > md-tabs md-tabs-wrapper {
    background: #fcfdfe;
    z-index: 3;
}

md-card.md-default-theme, md-card {
    background: #fcfdfe;
}

/* spinner */
.loaderWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.46);
    min-height: 50px;
    z-index: 10;
    text-align: center;
    left: 0;
    right: 0;
}

table .loaderWrapper {
    background: transparent;
}

.loaderWrapper md-progress-circular {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25px !important;
    margin-top: -25px !important;
}
.uneditableFormItemLoaderWrapper {
    position: relative;
}
.uneditableFormItemLoaderWrapper .loaderWrapper {
    left: unset;
}
.uneditableFormItemLoaderWrapper .loaderWrapper md-progress-circular {
    margin-top: -24px !important;
}
.loaderOverlayWrapperGlobal {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.8);
    z-index: 10000;
}
.loaderWrapperGlobal {
    position: absolute;
    top: 50%;
    left: 50%;
}
/* modal */
.iconParent {
    position: relative;
    padding-right: 35px;
    width: 100%;
}

.iconElem {
    position: absolute;
    right: 0;
    top: 5px;
    cursor: pointer;
}
/* content */
.wrapperRow {
    margin: 0 -15px;
}

.ppTable h4 {
    color: #54b095;
    padding: 0 10px;
}
.wbs-node-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.wbs-node-wrapper {
    border: 1px solid transparent;
    border-color: rgba(0,0,0,0.12) rgba(0,0,0,0.12) rgba(0,0,0,0.12) rgba(0,0,0,0.12);
}

.wbs-category-wrapper {
    border: 1px solid transparent;
    border-color: rgba(0,0,0,0) rgba(0,0,0,0.12) rgba(0,0,0,0) rgba(0,0,0,0.12);
}

.sov-category-wrapper {
    border: 1px solid transparent;
    border-color: rgba(0,0,0,0.12) rgba(0,0,0,0.12) rgba(0,0,0,0.12) rgba(0,0,0,0.12);
}

.sov-category-wrapper-expanded {
    border: 1px solid transparent;
    border-color: rgba(0,0,0,0.12) rgba(0,0,0,0.12) rgba(0,0,0,0) rgba(0,0,0,0.12);
}

.borderWrapper {
    border: 1px solid transparent;
    position: relative;
    padding: 5px;
    clear: both;
    border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0.12) rgba(0,0,0,0);
    margin-bottom: 2px;
}

.scrollable .borderWrapper {
    clear: none;
}

.mainAddButton {
    position: relative;
    z-index: 1;
    margin-top: 17px;
}
.childrenWrapper .mainAddButton {
    margin-top: 5px;
}
.childrenWrapper .borderWrapper:last-child {
    border-bottom: 0px solid rgba(0,0,0,0.12);
}

.childrenWrapper {
    font-size: .9em;
}

    .childrenWrapper .itemWrapper h3, .childrenWrapper .itemWrapper .noteBlock {
        margin-left: 10px;
    }

    .childrenWrapper .itemWrapper .noteBlock {
        margin-bottom: 5px;
    }

.noteBlock strong {
    color: #202020;
    font-weight: 400;
}

    .noteBlock strong span {
        color: rgba(0, 0, 0, 0.4);
    }

.childrenWrapper .itemWrapper .smallForm {
    margin: 0 .5% 10px;
}

.childrenWrapper .dangerWrapper .itemWrapper {
    overflow: visible;
    background: transparent;
}

.childrenWrapper .itemWrapper {
    overflow: hidden;
    background: #f3f3f3;
}

.backgroundWrapper {
    border: 1px solid #e5e6e7;
    position: relative;
    margin: 2px;
    padding: 0 10px;
}
.backgroundWrapperAlternate {
    border: 1px solid transparent;
    position: relative;
    margin: 2px;
    padding: 0 10px;
}

.childrenWrapper h2 {
    padding: 7px 0 6px;
    margin: 5px 0 0 0;
}

.roundCorners {
    border-radius: 3px;
    padding: 3px 10px;
    display: inline-block;
    margin-bottom: 3px;
}
h3 .roundCorners {
    padding: 1px 10px;
}
.itemWrapper h3 {
    margin-top: 0;
    padding-top: 11px;
}

md-card-content table {
    width: 100%;
}

md-card-content th, md-card-content td {
    padding: 8px;
}

.actionsFab {
    width: 100px;
}

td md-input-container:not(.managerSearch) {
    width: 100%;
}
.formTable > tbody > tr > td {
    border: 0;
    padding-top: 0px !important;
    height: --table-cell-height;
}


.permanentNotEditable .formTable tbody tr td {
    padding-left: 0;
}

.tableWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.inlineManagerWrapper .tableWrapper {
    position: static;
}

.paginationWrapper {
    margin-bottom: 16px;
}

.pageHeading h1 {
    font-size: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 15px;
}

#mainTable {
    min-width: 99.8%;
    width: 99.8%;
}

.mainTable {
    width: 100%;
    max-width: 100%;
}

    .mainTable th {
        padding: 8px;
        font-size: 13px !important;
        font-weight: bold;
    }

    .mainTable td {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
        height: 35px;
        vertical-align: top;
        font-size: 14px !important;
    }

.periodTable {
    table-layout: fixed;
    width: auto;
}

    .periodTable td {
        text-align: center;
        padding: 0 1px !important;
    }

    .periodTable th:first-child {
        border-left: 1px solid #ddd;
    }

    .periodTable th {
        text-align: center;
        padding: 8px 1px !important;
        border-right: 1px solid #ddd;
        min-width: 200px;
    }

    .periodTable .yearWrapper {
        width: auto;
    }

    .periodTable td > span {
        display: block;
    }

th {
    font-weight: 400;
    border: 0;
}

#mainTable th, #mainTable td, .mainTable tr > * {
    border-top-style: solid !important;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
    vertical-align: middle !important;
    border-color: #edf3f9 !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
}

table .table {
    margin-bottom: 0;
}

.ppTable md-input-container {
    margin: 0;
}

#mainTable th, #mainTable td, .ppTable th, .ppTable td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.instanceHeader {
    background: #edf3f9;
    margin: -16px -16px 0;
    padding: 10px 16px 0px;
    position: fixed;
    left: 15px;
    right: 15px;
    z-index: 5;
    border-bottom: 1px solid #e0e0e0;
}

.instanceHeaderTop {
    top: 70px;
}

.hasTabs .instanceHeader {
    top: 15px;
}
.filtersWrapper {
    background: rgba(255,255,255, .1);
    position: fixed;
    right: 1px;
    z-index: 10;
    bottom: 50px;
    top: 0;
    left: 0;
    cursor: auto !important;
    padding-top: 50px;
}

.modalFilters.filtersWrapper .filtersInnerWrapper {
    top: 0;
    bottom: 5px;
}

.modalFilters.filtersWrapper .filtersHeader {
    top: 10px;
}

.modalFilters.filtersWrapper .filtersFooter {
    bottom: 7px;
}
.shadowContainer {
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.paddedContainer {
    padding: 15px;
}
.annotationGridster>* {
    margin-top: 5px !important;
}
.annotationWrapper {
    width: 410px;
    max-width: 80%;
    margin: 0px auto;
    padding: 15px;
    top: 50%;
    position: relative;
    margin-top: -200px;
}
.filtersInnerWrapper {
    width: 300px;
    cursor: auto !important;
    padding: 65px 20px 50px 20px;
    position: fixed;
    right: 0;
    bottom: 50px;
    top: 175px;
}
.fileManager .filtersInnerWrapper {
    top: 230px;
}
.fileManager .filtersHeader {
    top: 240px;
}
.filtersFooter, .filtersHeader {
    position: fixed;
    width: 300px;
    right: 0;
    padding: 5px 20px;
}
.reportsFilters {
    position: relative;
}
.reportsFilters .filtersHeader {
    position: absolute;
    top: 0;
}
.form-field-select {
    margin-top: 8px;
    padding-top: 22px;
    display: flex;
    flex-direction: row-reverse;
}
.form-field-select itv-select {
    flex: 1 1 auto;
    width: 300px;
    display: block;
}

itv-select md-select .md-select-value.md-select-placeholder {
    color: #000;
    font-size: 90%;
    font-weight: bold;
}

.itv-select-form-cell md-select .md-select-value.md-select-placeholder{
    color: rgba(0,0,0,0.38);
    font-size: unset;
    font-weight: unset;
}
itv-select md-input-container > md-select {
    order: unset;
}

.form-field-checkbox {
    clear: both;
    margin-top: 29px;
}
.dateTimeWrapper {
    margin-top: 18px;
}
.pull-right {
    z-index: 1;
}
.pull-right.md-menu {
    position: relative;
}
.noMarginsFields {
    padding-top: 0;
    padding-bottom: 0;
}
.noMarginsFields .form-field-checkbox {
    margin-top: 0;
}
.form-field-attachment {
    margin-top: 20px;
    margin-bottom: 15px;
}

.emailContent .form-field-select, .emailEditForm .form-field-select {
    margin-top: 0px;
}

.emailContent .form-field-checkbox, .emailEditForm .form-field-checkbox {
    margin-top: 0px;
}

.emailContent .form-field-attachment, .emailEditForm .form-field-attachment {
    margin-top: 0px;
    margin-bottom: 0px;
}

form-table-cell md-select {
    margin-top: 12px;
}

.filtersFooter {
    bottom: 50px;
    text-align: right;
}

    .filtersFooter .md-button {
        margin: 0;
        width: 32%;
        min-width: 0;
    }

.filtersHeader {
    top: 190px;
}

.table td:not(.mat-mdc-cell) {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    height: 35px;
}

.table th {
    color: rgba(0, 0, 0, 0.9);
    text-transform: uppercase;
    font-size: 12px !important;
    font-weight: 500;
    background: #edf3f9;
}

.table.allBorders td, .table.allBorders th {
    border: 1px solid #ddd !important;
    white-space: normal !important;
}

#section-to-print .allBorders p {
    white-space: normal !important;
}

.table.noActionsTable td, .noActionBar .table td {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.mainTable tr:first-child td {
    border: 0;
}

#mainTable th, .mainTable th {
    border-bottom: 1px solid #edf3f9;
    border-left: 0;
    border-right: 0;
}

.selectTd {
    padding: 0 8px !important;
}

#mainTable th.multiselectCol {
    width: 60px !important;
    padding: 0 8px;
    text-align: center;
}

#mainTable .readEmails th.multiselectCol {
    width: 30px !important;
}

#mainTable th.statusCol {
    
    overflow: visible;
    text-align: center;
}

.actionsCol {
    width: 55px !important;
    max-width: 55px !important;
    overflow: visible !important;
}

table.rz-table th:last-child .rz-handle, table.rz-table th.multiselectCol .rz-handle {
    display: none !important;
}
/* labels */
.label, .label.label-default, .label.label-warning, .label.label-danger, .label.label-primary {
    background: transparent;
    color: #47888d;
    font-size: var(--base-font-size);
    font-weight: 400;
    border: 0;
    padding: 0;
}

.label-deactivated {
    color: rgba(0, 0, 0, 0.2);
}

.label.label-warning {
    color: #f59651;
}

.label.label-danger {
    color: red;
}

/* gridster */
gridster {
    background: #e7eaec !important;
}

.propField, gridster-item.propField .toolbarPlaceholder {
    background: #e1f0f4;
}

.propField.isRelation {
    background: #e4f6ef;
}

.propField.isSystemContract {
    background: #e3e6cc;
}

.propFieldsCol .borderWrapper {
    padding: 5px 10px;
}

.paidOver100 {
    background: #eabebe;
}

.itemModified {
    background: #fffec3;
}

gridster-item {
    border: 1px solid #e5e6e7 !important;
    border-radius: 3px !important;
    overflow: visible !important;
}

gridster-item span[class*="placeholder"] {
    display: inline-block;
    position: absolute;
    top: 50%;
    margin-top: -21px;
    text-align: left;
    padding-left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    padding-right: 80px;
    line-height: 1.2em;
    cursor: default;
    width: 100%;
}


gridster-item span.placeholder-select {
    padding-right: 73px;
}

gridster-item span.placeholder-radio, gridster-item span.placeholder-attachment {
    padding-left: 30px;
}

gridster-item span.placeholder-radio:before {
    display: block;
    position: absolute;
    left: 5px;
    top: 50%;
    margin-top: -7px;
    height: 14px;
    width: 14px;
    border: 2px solid #999;
    border-radius: 100%;
    content: "";
}

gridster-item span.placeholder-table {
    position: relative;
    z-index: 1;
}

gridster-item span.placeholder-datepicker {
    padding-right: 85px;
}

gridster-item span.placeholder-annotation {
    

}
.annotationHolder {
    padding-left: 20px;
}
gridster-item span.placeholder-multiselect::after {
    content: "×";
    display: block;
    position: absolute;
    top: 0;
    right: 22px;
    border: 1px solid #e7eaec;
    line-height: 0.7em;
    padding: 3px 0 0;
    bottom: 0;
    width: 22px;
    text-align: center;
    font-size: 16px;
}

gridster-item span.placeholder-multiselect::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5px;
    right: 44px;
    border: 1px solid #e7eaec;
}

gridster-item.tableHolder::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 33%;
    top: 33%;
    left: 0;
    right: 0;
    border-top: 1px solid #e5e6e7;
    border-bottom: 1px solid #e5e6e7;
}

gridster-item.tableHolder::before {
    content: "";
    display: block;
    position: absolute;
    left: 33%;
    right: 33%;
    top: 0;
    bottom: 0;
    border-left: 1px solid #e5e6e7;
    border-right: 1px solid #e5e6e7;
}

gridster-item .toolbarPlaceholder {
    background: #ffffff;
    display: block;
    border: 1px solid #e5e6e7;
    padding: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    /* color: rgb(34, 34, 34); */
    position: relative;
    border-top: 5px solid #000;
    opacity: .5;
}

gridster-item .toolbarPlaceholder::after {
    position: absolute;
    display: block;
    content: "";
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    background: rgba(249, 248, 248, 0.4);
}

gridster-item .toolbarPlaceholder .btn {
    min-width: 0;
    margin: 0 -2px;
    padding: 0 7px;
    background: white;
    color: #000;
    box-shadow: none;
    font-size: var(--base-font-size);
}


.disabledActions {
    position: relative;
    opacity: .8;
}

    .disabledActions::after {
        content: '';
        display: block;
        position: absolute;
        top: -5px;
        bottom: -5px;
        left: 0;
        right: 0;
        z-index: 6;
    }

    .disabledActions > *:not(.sortableFormWrapper) {
        opacity: .6;
    }

    .disabledActions>*:not(.sortableFormWrapper) .md-button, .disabledActions>*:not(.sortableFormWrapper) .md-button.md-primary.md-raised {
        background-color: transparent;
        color: rgba(0,0,0,0.38);
        cursor: default;
        box-shadow: none;
    }
.previewTableWrapper {
    overflow: visible !important;
}
.hasOrderContainer {
    padding-left: 30px;
    position: relative;
}

.outerOrderWrapper {
    position: absolute;
    width: 30px;
    left: 0;
    top: 50%;
    margin-top: -17px;
    height: 44px;
}

.orderWrapper {
    position: absolute;
    left: 0;
    width: 28px;
    text-align: center;
}

.orderUp {
    top: 0;
}

.orderDown {
    bottom: 0;
}

.spinnerWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
}

.savingWrapper {
    position: fixed;
    bottom: 90px;
    left: 0;
    right: 0;
    z-index: 3;
    text-align: center;
}

    .savingWrapper span {
        background: #ffffcc;
        display: inline-block;
        padding: 5px;
        min-width: 150px;
    }

gridster-item i.selectMark {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 60px;
    font-size: 20px;
}

gridster-item i.selectMarkHalf {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 30px;
    font-size: 20px;
}

gridster-item i.attachMark {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 4px;
    font-size: 20px;
}

gridster-item i.checkboxMark {
    width: 23px;
    position: absolute;
    top: 50%;
    margin-top: -11px;
    left: 5px;
}

gridster-item i.datepickerMark {
    position: absolute;
    right: 60px;
    top: 50%;
    margin-top: -10px;
}

gridster-item i.datepickerMarkHalf {
    position: absolute;
    right: 30px;
    top: 50%;
    margin-top: -10px;
}

gridster-item .actionsWrapper {
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -11px;
    font-size: 17px;
    z-index: 4;
}

gridster-item div.button-holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.destroy-button {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
}
/* context containers */
.deleteOverlay {
    text-align: center;
    padding: 10px 0;
}

.hover:hover {
    background: #edf3f9;
}

.hover p {
    margin: 10px 0;
}

.hover.whiteHover:hover {
    background: white;
}

.table-hover>tbody>tr:hover {
    background-color: #edf3f9;
}

/* fixed menu */
.fixedWidthCol {
    width: 180px;
    float: left;
    position: relative;
    margin-right: -180px;
    margin-left: 16px;
    z-index: 10;
}

.fixedWidthCol > .ibox-content {
    position: fixed;
    width: 180px;
    padding: 10px 15px 10px 0;
    bottom: 130px;
}
.explorerFolders,
.explorerFolders > .ibox-content {
    width: 275px;
}

.fixedWidthCol ul {
    list-style: none outside;
    padding: 0;
    margin-bottom: 0;
}

.fluidWidthCol {
    padding-left: 200px;
    width: 100%;
    min-height: 300px;
    float: left;
    /*transition: all .3s ease;*/
    position: relative;
    z-index: 3;
    pointer-events: none;
}

.fluidWidthCol * {
    pointer-events: auto;
}
.fileManager {
    padding-left: 290px;
    height: calc(100% - 50px);
    overflow: auto;
}
    
.foldersWrapper {
    transition: all .3s ease;
    width: 160px;
    height: 100%;
}

.breadcrumb {
    font-family: 'Raleway', sans-serif;
}

.breadcrumb:not(h4) {
    padding: 16px 0 0 16px;
    margin-bottom: unset;
    font-size: medium;
    font-style: italic;
    border-bottom: 1px solid #e7eaec;
    border-radius: 0;
    background: #fff;
    position: relative;
    z-index: 5;
    height: 50px;
}

/*
    Below md-backdrop/md-dialog-container styling fixes backdrop issue
    when opening modals inside of a fullScreen modal. 
*/
md-backdrop.md-dialog-backdrop:nth-of-type(even) {
    z-index: 81;
}

md-backdrop.md-dialog-backdrop:nth-of-type(odd) {
    z-index: 79;
}

div.md-dialog-container {
    z-index: 82;
}

div.md-dialog-container[aria-hidden="true"] {
    z-index: 80;
}

.breadcrumb a {
    color: #646668;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    padding-right: 12px;
    padding-left: 4px;
    padding-bottom: 4px;
}
.breadcrumb > .active, .active {
    color: #646668;
    font-weight: 700;
}

    .breadcrumb a::after {
        display: block;
        position: absolute;
        right: 0;
        content: "\f105";
        font: normal normal normal 20px/1 FontAwesome;
        top: -1px;
    }

/* file manager */
.explorerFolders > .ibox-content {
    top: 200px;
    bottom: 45px;
    padding-right: 0;
    height: auto;
}

md-dialog-content .explorerFolders > .ibox-content {
    top: 121px;
}

.projectExplorer .explorerFolders > .ibox-content {
    padding-top: 70px;
}

.fmProjectSelect {
    width: 160px;
    position: fixed;
    margin-top: 25px;
    z-index: 4;
    margin-left: 10px;
}

.explorerFolders treecontrol > ul > ul {
    max-height: 100%;
    /* Fix for loading spinner in project file folder tree */
    overflow: auto;
}

.fileInfo td:first-child > span:before, .folderInfo td:first-child > span:before, .isFolder a:before,
.fileInfo .gridItem > div:nth-child(2):before, .folderInfo .gridItem > div:nth-child(2):before, .fileInfo .item:before, span.fileInfo:before, .noProfilePic {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font: normal normal normal 24px/1 "Material Design Icons";
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 8px;
    position: relative;
    top: 4px;
    line-height: .7em;
}

.folderIcon.sharedFolder:before {
    content: "\F024c" !important;
}

.noProfilePic:before {
    content: "\F0009";
}

.tableSmallTmb {
    margin-right: 8px;
    width: 24px;
    border-radius: 50%;
}

.fileManager .tableSmallTmb {
    border-radius: 0;
}

span.fileInfo:before, .tableIcon {
    min-width: 24px;
    min-height: 24px;
    color: #8f8f8f;
    margin-right: 8px;
}

.fmSymbol {
    position: absolute;
    left: 15px;
    font-size: 16px !important;
    bottom: -5px;
    background: rgba(250, 250, 250, 1);
    border-radius: 4px;
    line-height: 1.1em;
    /* color: #2e7eb8; */
}

.fmSymbolEI {
    position: absolute;
    font-size: 24px !important;
    background: rgba(250, 250, 250, 1);
    border-radius: 4px;
    line-height: 1.1em;
    left: 0px;
    top: 1px;
}

.mdi-earth.fmSymbol {
    left: -6px;
    font-size: 12px !important;
    bottom: -1px;
    border-radius: 50%;
    padding: 1px;
}
.gridItem .mdi-earth.fmSymbol {
    left: 5px;
    bottom: 5px;
}
li .mdi-earth.fmSymbol {
    bottom: 0;
}
.tableIcon {
    font-size: 24px !important;
    display: inline-block;
    vertical-align: middle;
}

.thumbWrapper {
    height: 140px;
    overflow: hidden;
    display: block;
}

.biggerThumbs {
    width: 65px;
}

span.bigIcon:before {
    font-size: 85px;
    width: 100%;
    text-align: center;
    height: 140px;
    padding-top: 25px;
    color: #999;
    position: relative;
    top: 0px;
    margin: 0;
    background: white;
}

.thumbWrapper {
    height: 140px;
    overflow: hidden;
    display: block;
    position: relative;
}

    .thumbWrapper .fmSymbol {
        left: auto;
        right: 0;
        bottom: 0;
        font-size: 20px !important;
    }

span.bigIcon:before {
    font-size: 95px;
    width: 100%;
    text-align: center;
    height: 140px;
    padding-top: 36px;
    color: #999;
    position: relative;
    top: 0px;
    margin: 0;
    background: white;
}

.fileInfo .gridItem > div:nth-child(2):before, .folderInfo .gridItem > div:nth-child(2):before {
    font-size: 22px;
    top: 3px;
}

.fileManager .ibox-content {
    padding-top: 0;
}

.fileManager .btn i:nth-child(2) {
    position: absolute;
    color: #1ab394;
    top: 6px;
    right: 1px;
    font-size: var(--base-font-size);
}

.layoutControls i {
    line-height: .7em;
    position: relative;
    top: 2px;
    min-width: 20px;
}

.fileManagerGrid .gridItemsWrapper {
    width: 210px;
    float: left;
    max-width: 50%;
}

.gridItemsWrapper {
    width: 50%;
    float: left;
}

.correspondenceGrid .gridItemsWrapper {
    width: 100%;
}

.gridItem {
    border: 1px solid #edf3f9;
    padding: 10px;
    margin: 5px;
    position: relative;
    background: #fcfdfe;
    overflow: hidden;
}

.contractsGrid .col-1of2 strong {
    float: right;
    margin: 0 15px 0 0;
    font-weight: 400;
}

.fileManagerGrid .gridItem {
    padding: 0;
}

.gridItem:hover {
    background: #edf3f9;
}

.fileManagerGrid .gridItem .pull-right {
    top: auto;
    bottom: 2px;
}

.gridItem .pull-right {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 2;
}

.gridItem .ellipsedText {
    display: block;
    margin: 0;
    padding-right: 35px;
}

    .gridItem .ellipsedText.spanLabel {
        padding-right: 15px;
    }

.gridItem .col-1of2 .statusItem {
    position: absolute;
    width: 80%;
    left: 10%;
    top: 0;
    text-align: center;
}

.gridItem hr {
    margin-top: 10px;
    margin-bottom: 10px;
}

.fileManagerGrid .gridItem .ellipsedText {
    min-height: 45px;
    padding: 10px 35px 10px 10px;
    display: block;
}

.fileInfo .gridItem > div:nth-child(2), .folderInfo .gridItem > div:nth-child(2) {
    white-space: nowrap;
    padding: 6px 35px 10px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.fileInfo.failedProgress .item:before {
    content: "\F0028";
    color: red;
}

    .fileInfo td:first-child > span:before, .fileInfo .gridItem > div:nth-child(2):before, .fileInfo .item:before, span.fileInfo:before {
        content: "\F021a";
    }

    .folderInfo td:first-child > span:before, .isFolder a:before, .folderInfo .gridItem > div:nth-child(2):before, span.folderIcon:before {
        content: "\F024B";
        color: #868686;
    }

.folderInfo td:first-child > span.mdi-folder-marker:before, span.folderIcon.mdi-folder-marker::before {
    content: "\F126D";
}

.isFolder a.active:before {
    content: "\F007c";
    color: #1ab394;
}

tr[class*="image"] td:first-child > span:before, div[class*="image"] .gridItem > div:nth-child(2):before,
div[class*="image"] .item:before, span[class*="image"]:before {
    content: "\F02e9";
    color: #2d527c;
}

span[class*="image"].loadingThumb {
    position: relative;
}

    span[class*="image"].loadingThumb:after {
        border-radius: 50%;
        width: 15px;
        height: 15px;
        content: "";
        /* display: inline-block; */
        left: 1px;
        top: -1px;
    }

span[class*="image"].bigIcon.loadingThumb:after {
    left: 50%;
    margin-left: -8px;
}

span[class*="image"].loadingThumb:after {
    margin: 0;
    font-size: 10px;
    position: absolute;
    text-indent: -9999em;
    border-top: 3px solid rgba(255, 255, 255, .5);
    border-right: 3px solid rgba(13, 96, 103, 0.68);
    border-bottom: 3px solid rgba(13, 96, 103, 0.68);
    border-left: 3px solid rgba(13, 96, 103, 0.68);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

tr[class$="pdf"] td:first-child > span:before, div[class$="pdf"] .gridItem > div:nth-child(2):before,
div[class$="pdf"] .item:before, span[class$="pdf"]:before {
    content: "\F0226";
    color: #e2574c;
}

tr[class$="compressed"] td:first-child > span:before, div[class$="compressed"] .gridItem > div:nth-child(2):before,
div[class$="compressed"] .item:before, span[class$="compressed"]:before {
    content: "\F05c4";
    /*javascript */
}

tr[class*="htm"] td:first-child > span:before, div[class*="htm"] .gridItem > div:nth-child(2):before,
div[class*="htm"] .item:before, tr[class*="javascript"] td:first-child > span:before,
div[class*="javascript"] .gridItem > div:nth-child(2):before, div[class*="javascript"] .item:before,
span[class*="htm"]:before, span[class*="javascript"]:before {
    content: "\F0171";
}

tr[class$="plain"] td:first-child > span:before, div[class$="plain"] .gridItem > div:nth-child(2):before,
div[class$="plain"] .item:before, span[class$="plain"]:before {
    content: "\F021a";
}
.excelSymbol {
    color: #0f9d58;
}
.pdfSymbol {
    color: #e2574c;
}
tr[class*="word"] td:first-child > span:before, div[class*="word"] .gridItem > div:nth-child(2):before,
div[class*="word"] .item:before, span[class*="word"]:before {
    content: " ";
    background-image: url(images/office/docx.svg);
    background-repeat: no-repeat;
    background-position: center;
}

tr[class*="excel"] td:first-child > span:before, div[class*="excel"] .gridItem > div:nth-child(2):before,
div[class*="excel"] .item:before, span[class*="excel"]:before,
tr[class*="sheet"] td:first-child > span:before, div[class*="sheet"] .gridItem > div:nth-child(2):before,
div[class*="sheet"] .item:before, span[class*="sheet"]:before {
    content: " ";
    background-image: url(images/office/xlsx.svg);
    background-repeat: no-repeat;
    background-position: center;
}

tr[class*="powerpoint"] td:first-child > span:before, div[class*="powerpoint"] .gridItem > div:nth-child(2):before,
div[class*="powerpoint"] .item:before, span[class*="powerpoint"]:before,
tr[class*="presentation"] td:first-child > span:before, div[class*="presentation"] .gridItem > div:nth-child(2):before,
div[class*="presentation"] .item:before, span[class*="presentation"]:not(.mat-mdc-chip-action):before {
    content: " ";
    background-image: url(images/office/pptx.svg);
    background-repeat: no-repeat;
    background-position: center;
}

/* user color-profile */
.clickThroughFixed {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 2;
    white-space: nowrap;
    left: 0;
    top: 0;
    overflow: hidden;
    cursor: default !important;
}

.userProfileWrapper {
    height: 100%;
    margin: 24px;
    cursor: default !important;
}

.userDetailsWrapper {
    max-height: 100%;
    cursor: default !important;
}

.upForm {
    background: #fff;
}

.upTop {
    padding: 15px;
    background: #fff;
    white-space: normal;
    position: relative;
}

.uploadBtn {
    position: absolute;
    left: 35px;
    bottom: 15px;
    z-index: 15;
    background: rgba(233, 236, 237, 0.77);
}

.upSection {
    border-top: 1px solid #e0e0e0;
    padding: 10px 15px;
}

    .upSection .md-button {
        text-align: left;
        margin: 0;
        width: 100%;
    }

    .upSection i {
        float: right;
    }

.userDetailsWrapper i.fa {
    width: 33%;
    float: left;
    margin-right: 3%;
    margin-bottom: 10px;
}
/* correspondence */
.fromLine {
    font-size: 15px;
}



.viewEmailWrapper .messageWrapper {
    border-top: 1px solid #e7eaec;
}

.viewEmailWrapper .expandedMessage, .viewEmailWrapper .collapsedMessage {
    display: none;
}

.viewEmailWrapper .collapsedMessage {
    background-color: #f9f8f8;
    padding: 10px;
    cursor: pointer;
}

.viewEmailWrapper .collapsed {
    display: none;
}

.viewEmailWrapper .collapsedMessage .preview {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.viewEmailWrapper blockquote {
    font-size: var(--base-font-size);
}

.viewEmailWrapper blockquote {
    padding: 10px 10px;
    margin: 0 0 10px;
    border-left: 2px solid #eee;
}

.staticMessage {
    padding: 10px;
}

.hiddenEmailsCount {
    padding: 2px;
    text-align: center;
    position: relative;
    cursor: pointer;
    background-color: #f9f8f8;
    border-top: 1px solid #e7eaec;
}

    .hiddenEmailsCount > span {
        visibility: hidden;
        position: relative;
        z-index: 4;
    }

    .hiddenEmailsCount.moreThan2 > span, .hiddenEmailsCount > .draftWrapper {
        visibility: visible;
        background-color: #f9f8f8;
        padding: 0 10px;
    }

    .hiddenEmailsCount:after, .hiddenEmailsCount:before {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        height: 1px;
        background-color: #e7eaec;
        left: 0;
    }

    .hiddenEmailsCount.moreThan2:before {
        bottom: 27%;
        display: block;
    }

    .hiddenEmailsCount.moreThan2:after {
        top: 27%;
    }

.emailFolders > .ibox-content {
    top: 102px;
}

.collapsedList .customFolder {
    display: none;
}

.allFoldersWrap {
    width: 160px;
    max-height: 100%;
}

.fixedWidthCol ul a {
    display: block;
    padding: 5px 5px 5px 30px;
    color: #202124;
    text-decoration: none;
    position: relative;
}

.foldersWrapper li a.active::before {
    color: #202124;
}

.fixedWidthCol .active {
    font-weight: 700;
    background: #edf3f9;
}

.fixedWidthCol .hasUnread {
    font-weight: 700;
}

a.foldersToggle {
    width: 160px;
    margin-top: 10px;
}

.foldersToggle {
    padding: 7px 0;
    margin: 0px 0 0;
    border-top: 1px solid #e7eaec;
    position: relative;
    display: block;
    color: #676a6c;
}

.readEmails td {
    background-color: #fff;
}
.columWrapper {
    display: flex;
    align-items: center;
    column-gap: 4px;
}
.colorCodeWrapper {
    display: inline-block; 
    width: 12px; 
    height: 12px; 
    background-color: green;
    border-radius: 30%;
}
.switchWrapper {
    flex: 1 1 auto;
}
.switchWrapper, .joint {
    display: block;
}
.emailTable td:nth-child(3) {
    padding-right: 18px;
}

    .emailTable td:nth-child(3) .joint.conditionalJoint > span:last-child {
        color: red;
        font-weight: normal;
    }

    .emailTable td:nth-child(3) .joint > span:first-child {
        text-overflow: ellipsis;
        max-width: 100px;
        overflow: hidden;
        display: block;
        float: left;
        margin-right: 3px;
    }


   
.emailTable #mainTable {
    table-layout: fixed;
    white-space: nowrap;
}

    .emailTable #mainTable td {
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        cursor: hand;
        cursor: pointer;
    }

        .emailTable #mainTable td:last-child {
            overflow: visible;
        }

    .emailTable #mainTable tr td:first-child, .multiselectCol {
        min-width: 40px;
        max-width: 40px;
        width: 4%;
        text-overflow: clip;
    }

    .emailTable #mainTable tr td:nth-child(2) {
        width: 10px;
        padding: 0;
    }

    .emailTable #mainTable tr td:nth-child(3) {
        width: 170px;
        padding: 0;
    }

    .emailTable #mainTable tr td:nth-child(4) {
        max-width: 45%;
    }

    .emailTable #mainTable tr td:nth-child(4) {
        color: #a6b7bf;
    }

    .emailTable #mainTable tr td:nth-child(5) {
        width: 36px;
    }

    .emailTable #mainTable tr td:nth-child(6) {
        width: 85px;
        text-overflow: unset;
        overflow: visible;
    }

    .emailTable #mainTable tr td:last-child {
        width: 65px;
    }

    .emailTable #mainTable tr td:nth-child(4) span span span:first-child {
        color: #676a6c;
        margin-right: 0;
    }

.unreadEmails td:nth-child(3), .unreadEmails td:nth-child(4) span span span:first-child {
    font-weight: 700;
}

.composeBtn {
    margin: 0;
}

.isSelected {
    background-color: #ffffcc !important;
}

.correspondenceGrid {
    display: none;
}

.emailTable .layoutControls {
    display: none;
}

.correspondenceGrid .gridItem {
    border: 0px solid #e8ebed;
    border-top: 1px solid #e8ebed;
    margin: 0;
    padding-right: 7px;
    padding-top: 4px;
}

    .correspondenceGrid .gridItem:after {
        clear: both;
        display: block;
        content: "";
    }

    .correspondenceGrid .gridItem div:nth-child(6) .joint span:first-child {
        display: block;
        font-size: 14px;
    }

    .correspondenceGrid .gridItem div:nth-child(6) .joint span:nth-child(2) {
        display: block;
        font-size: 12px;
        color: #a6b7bf;
    }

    .correspondenceGrid .gridItem div:nth-child(5) .conditionalJoint span:nth-child(3) {
        color: red;
        font-weight: 400;
    }

    .correspondenceGrid .gridItem div:nth-child(2) {
        float: right;
        margin-top: 10px;
        color: rgba(0, 0, 0, 0.4);
    }

    .correspondenceGrid .gridItem div:nth-child(3), .correspondenceGrid .gridItem div:nth-child(4) {
        margin-top: 13px;
        float: right;
        color: rgba(0, 0, 0, 0.4);
    }

    .correspondenceGrid .gridItem div:nth-child(3) {
        font-size: 11px;
    }

    .correspondenceGrid .gridItem div:nth-child(4) {
        margin-right: 7px;
    }

    .correspondenceGrid .gridItem div:nth-child(5) {
        font-size: 16px;
        padding-top: 10px;
        margin-left: 40px;
    }

    .correspondenceGrid .gridItem div:nth-child(6) {
        padding: 0px 0 10px 0;
        margin-left: 40px;
    }

    .correspondenceGrid .gridItem .pull-right {
        position: relative;
        clear: right;
        top: 0;
        right: 0;
        margin-left: 7px;
        margin-bottom: 10px;
    }

.correspondenceGrid .selectCol {
    position: absolute;
    top: 24px;
    bottom: 0;
    width: 30px;
    margin-top: -11px;
    left: 7px;
}

.correspondenceGrid .unreadEmails div:nth-child(5), .correspondenceGrid .unreadEmails div:nth-child(6) .joint span:first-child {
    font-weight: 700;
}

.correspondenceGrid .readEmails {
    background-color: #f9f8f8;
}

.foldersWrapper li a:before {
    display: block;
    font: normal normal normal 14px/1 FontAwesome;
    font: normal normal normal 20px/1 "Material Design Icons";
    position: absolute;
    left: 5px;
    top: 5px;
    color: #767676;
}

.systemEmailFolder-1:before {
    content: "\F0687";
}

.systemEmailFolder-2:before {
    content: "\F048A";
}

.systemEmailFolder-3:before {
    content: "\F09EE";
}

.systemEmailFolder-4:before {
    content: "\F01B4";
}

.systemEmailFolder-5:before {
    content: "\F120E";
}

.systemEmailFolder-6:before {
    content: "\F0B78";
}

.customEmailFolder:before {
    content: "\F24B";
}

.systemEmailFolder-7:before {
    content: "\F0687";
}

.systemEmailFolder-8:before {
    content: "\F0B92";
    padding-left: 10px;
}

.systemEmailFolder-8 {
    content: "\F0B92";
    padding-left: 40px !important;
}

.responsiveEmailHeader {
    display: none;
    border-bottom: 1px solid #e8ebed;
    padding-left: 10px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
    top: 85px;
    background: white;
}

.responsiveEmailHeader h3 span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.toggleSelectAll {
    margin-left: 16px;
}

.toggleSelectAll md-checkbox {
    margin-bottom: 0;
    margin-top: 10px;
}

.mobileCompose {
    position: fixed;
    bottom: 90px;
    right: 20px;
    display: none;
    box-shadow: 0 3px 17px 0px rgba(0, 0, 0, 0.73);
    z-index: 4;
    font-size: 20px !important;
}

.responsiveEmailHeader .menuToggle {
    float: right;
    position: relative;
    z-index: 1;
    margin-top: -5px;
    border: 0;
}
/* drafts */
.soloConvWrapper {
    position: fixed;
    bottom: 40px;
    right: 5px;
    z-index: 2;
    white-space: nowrap;
    left: 0;
    overflow: hidden;
    pointer-events: none;
}

    .soloConvWrapper > div > * {
        white-space: normal;
        text-align: left;
    }

.soloConvoInner md-input-container * {
    text-align: left;
}

.emailContent {
}

    .viewEmailWrapper > div > form-layout > div > div > form > div > div > form-field > div > md-input-container > div,
    .emailContent > form-layout > div > div > form > div > div > form-field > div > md-input-container > div,
    .emailContent > form-layout > div > div > form > div > div > form-field > div > md-input-container .contactFieldError {
        display: none;
    }

.emailContent > form-layout > div > div > form > div > div > form-field > div > md-input-container,
.viewEmailWrapper > div > form-layout > div > div > form > div > div > form-field > div > md-input-container {
    margin: 0;
    overflow: hidden;
}

    .emailContent > form-layout > div > div > form > div > div > form-field > div > md-input-container md-checkbox,
    .viewEmailWrapper > div > form-layout > div > div > form > div > div > form-field > div > md-input-container md-checkbox {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 5px;
    }

    .emailContent > form-layout > div > div > form > div > div > form-field > div > md-input-container .md-errors-spacer {
        display: none;
    }

.item {
    overflow: hidden;
    padding-top: 4px;
}

.soloConvoInner {
    text-align: right;
    width: 2000%;
    margin-left: -1900%;
    position: relative;
    right: 0;
}

.draftAttachmentsWrapper {
}

.newEmailWrapper {
    display: inline-block;
    background-color: #fff;
    box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.48);
    width: 1.95%;
    margin-right: 0.05%;
    position: relative;
    pointer-events: auto;
    margin-bottom: 1px;
    overflow: hidden;
    /* width: 500px;
    max-width: 100%;*/
}

md-dialog-content .newEmailWrapper {
    width: 100% !important;
    box-shadow: none;
    display: block;
}

.emailEditForm {
    position: relative;
}

.viewEmailWrapper .attachmentWrapper, .viewEmailWrapper .attachmentWrapper .actionBar {
    top: 95px;
}

    .viewEmailWrapper .attachmentWrapper h4 {
        top: 55px;
    }

.openMenuFormWrapper .attachmentWrapper h4, .openMenuFormWrapper .attachmentWrapper, .openMenuFormWrapper .attachmentWrapper .actionBar, .openMenuFormWrapper .filesWrapper {
    left: 200px;
}

.newEmailWrapper .attachButtonWrapper, .emailEditForm .attachButtonWrapper {
    position: absolute;
    bottom: 13px;
    left: 118px;
    z-index: 2;
}

.md-dialog-container .newEmailWrapper .attachButtonWrapper, .emailEditForm .attachButtonWrapper {
    bottom: -42px;
}

.newEmailWrapper .attachmentsWrapper, .emailEditForm .attachmentsWrapper {
    padding: 0 10px;
}

.newEmailWrapper.collapsedMsg {
    width: 0.95%;
}

@media screen and (max-width: 1400px ) {
    .newEmailWrapper.collapsedMsg {
        width: 1.2%;
    }

    .newEmailWrapper {
        width: 2.44%;
    }
}

@media screen and (max-width: 1024px ) {
    .newEmailWrapper.collapsedMsg {
        width: 1.61%;
    }

    .newEmailWrapper {
        width: 3.3%;
    }
}

.emailInfo {
}

.newEmailWrapper .messageBody {
    /*width: 500px; */
    max-width: 100%;
    margin: 10px;
}

.collapsedMsg {
}

    .collapsedMsg .messageBody, .collapsedMsg .emailInfo, .collapsedMsg .draftActionsWrapper, .collapsedMsg .emailContent {
        display: none;
    }

.messageBody .progress {
    height: 7px;
}

.headerWrapper {
    background: black;
    color: #fff;
    padding: 10px 12px;
    cursor: pointer;
    position: relative;
}

    .headerWrapper button {
        position: relative;
        text-align: center;
        top: -5px;
        color: #fff;
    }

    .headerWrapper .pull-right {
        position: absolute;
        right: 5px;
        top: 5px;
    }

.collapsedMsg .headerWrapper {
    width: auto;
}

.headerWrapper > span {
    display: block;
    width: 100%;
    padding-right: 70px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.collapsedMsg .headerWrapper > span {
    width: 100%;
    padding-right: 70px;
}

.newEmailLoader {
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

    .newEmailLoader .sk-spinner {
        margin-top: 50%;
    }

@media (max-width: 959px) {
    md-dialog.md-dialog-fullscreen {
        min-height: 100%;
        min-width: 100%;
        height: 100% !important;
        border-radius: 0;
    }
}

@media screen and (max-width: 950px) {
    .emailTable .tableView {
        display: none;
    }

    .emailTable .inlineManagerWrapper .tableView {
        display: block;
    }

    .correspondenceGrid {
        display: block;
    }

    .pdf-markup-grid-items-wrapper {
        width: 50%;
        float: left;
    }
}

@media screen and (min-width: 725px) {
    .correspondenceMenu:before {
        position: absolute;
        background: #fff;
        width: 100%;
        height: 31px;
        top: -30px;
    }
}

@media screen and (max-width: 724px) {
    body {
        font-size: 14px;
    }

    .gridItemsWrapper {
        width: 100%;
        float: none;
    }

    .pdf-markup-grid-items-wrapper {
        width: 100%;
        float: none;
    }

    .noContractHeading {
        padding-top: 12px;
    }

    .hideCollapsedMenu, .desktopMenuButton {
        display: none !important;
    }

    .mobileMenuButton {
        display: inline-block !important;
    }

    .showCollapsedMenu {
        width: 100%;
        cursor: default;
    }

    .visibleMenu .pageWrapper {
        margin-left: 0;
    }

    .visibleMenu .navigationWrapper, .visibleMenu.pageWrapper, .visibleMenu .pageHeading, .visibleMenu .actionBar, .visibleMenu .contentInnerWrapper > md-tabs md-tabs-wrapper, .visibleMenu .breadcrumb {
        margin-left: 0;
    }

    .mobileVisibleMenu .navigationWrapper, .mobileVisibleMenu.pageWrapper, .mobileVisibleMenu .pageHeading, .mobileVisibleMenu .actionBar,
    .mobileVisibleMenu .contentInnerWrapper > md-tabs md-tabs-wrapper, .mobileVisibleMenu .breadcrumb, .mobileVisibleMenu .pageWrapper {
        margin-left: 200px;
        margin-right: -200px;
    }

    .visibleMenu .navigationInnerWrapper .logoWrapper {
        left: 35px;
    }

    .mobileVisibleMenu .navigationInnerWrapper .logoWrapper {
        left: 0;
    }

    .contentWrapper {
        position: relative;
        z-index: 10;
    }
    .correspondenceGrid .gridItemsWrapper, .correspondenceMenu.actionBar {
        padding: 0 16px;
    }
    .actionBar:after {
        width: 104%;
        margin: 0 -16px;
    }
    .explorerFolders {
        display: none;
    }

    .emailFolders {
        position: fixed;
        width: 100%;
        background: rgba(0,0,0,0);
        bottom: 0;
        top: 0;
        left: -200%;
        transition: background .3s ease;
        z-index: 3000;
    }

    .newEmailWrapper {
        width: 4.9%;
    }

    .soloConvWrapper {
        bottom: 5px;
    }

    .contentOuterWrapper:before {
        margin-top: -14px;
    }

    .collapsedMenu .emailFolders {
        left: 0;
        background: rgba(0, 0, 0, 0.6);
    }

    .emailTable {
        padding-left: 0;
        margin-top: 0;
    }

    .mobileCompose {
        display: block;
    }

    .responsiveEmailHeader {
        display: block;
        top: 55px;
        padding: 15px 10px 13px;
    }

        .responsiveEmailHeader h3 {
            margin: 0;
            font-size: 24px;
        }

    .mobileBtn {
        display: inline-block;
    }

    .pageWrapper {
        padding-top: 55px;
    }

    .pageHeading h1 {
        margin: 0;
    }

    .printDashboardBtn, .filterDashboardBtn {
        top: 62px;
    }

    .contractWrapper {
        margin-top: -6px;
    }

    .actionBar, .pageHeading {
        left: 0;
        right: 0;
    }

    .actionBar {
        top: 115px;
    }

    .underTabs.actionBar, .fileManagerMenu.actionBar {
        top: 165px;
    }

    .underTabsActions .actionBar {
        top: 165px;
    }

    .contentWrapper {
        margin: 0;
        padding: 0 0 20px 0;
    }

    .clientLogo {
        max-height: 40px;
    }


    .breadcrumb, .contentInnerWrapper > md-tabs md-tabs-wrapper {
        left: 0;
        right: 0;
    }

    .breadcrumb:not(h4), .fileManagerMenu {
        margin-left: 0;
        padding-left: 10px;
        left: 0;
        top: 115px;
    }

    .fmProjectSelect {
        margin-top: 0;
        margin-left: 0;
        right: 0;
        top: 72px;
    }

    .fileManager {
        padding-left: 0;
    }

    .logoWrapper {
        font-size: 30px;
    }

    .navigationWrapper {
        height: 55px;
    }

    .actionBar .searchForm {
        display: none;
    }

    .correspondenceMenu {
        padding-left: 0px;
    }

        .correspondenceMenu.actionBar {
            margin-left: 0 !important;
            top: 118px;
        }

    .emailFolders .ibox-content {
        padding: 0;
        background: transparent;
    }

    .emailFolders::after, .emailFolders .ibox-content {
        background: white;
        transition: all .3s ease;
        left: -200%;
        width: 60%;
        min-width: 250px;
        max-width: 450px;
        top: 0;
    }

    .emailFolders::after {
        position: fixed;
        bottom: 0;
        content: "";
        display: block;
    }

    .collapsedMenu .emailFolders .ibox-content, .collapsedMenu .emailFolders::after {
        left: 0;
        padding-bottom: 64px;
        top: 55px;
    }

    .allFoldersWrap, .foldersWrapper, a.foldersToggle, div.foldersToggle {
        width: 100%;
    }

    .emailFolders
    .fixedWidthCol ul a {
        padding: 10px;
    }

    .emailFolders .composeBtn {
        display: none;
    }

    .emailFolders .ibox-content {
        bottom: 77px;
        z-index: 2;
    }

    .fixedWidthCol .customEmailFoldersList {
        border-top: 1px solid #e7eaec;
        margin: 10px 0;
        padding-top: 10px;
    }

    .foldersWrapper li a {
        padding: 10px 10px 10px 38px;
    }

        .foldersWrapper li a:before {
            left: 10px;
            top: 10px;
        }

    .foldersToggle {
        padding: 17px 10px 7px;
        margin-top: 10px;
    }
}

/* responsive tables */
@media screen and (max-width: 1024px) {
    /* tablet view*/

    .hideOnTablet {
        display: none;
    }

    .mainTable th, .mainTable td {
        display: none;
    }
        /* .mainTable th.tabletKeep th, .mainTable td.tabletKeep td, */
        .mainTable th.tabletKeep, .mainTable td.tabletKeep,
        
        .mainTable th.actionsCol, .mainTable td.actionsCol,
        .mainTable th.multiselectCol, .mainTable td.multiselectCol,
        .mainTable th.selectTd, .mainTable td.selectTd {
            display: table-cell;
        }


    .emailTable .table:not(.customResponsive) td, .emailTable .table:not(.customResponsive) td.statusCol {
        display: table-cell;
    }
}

/*@media screen and (max-width: 874px) {
    .table:not(.customResponsive) td:nth-child(4):not(.actionsCol), 
    .table:not(.customResponsive) th:nth-child(4):not(.actionsCol) {
        display: none;
    }

    .emailTable .table:not(.customResponsive) td:nth-child(4) {
        display: table-cell;
    }
} */

@media screen and (max-width: 600px) {
    .avatarWrapper {
        float: none;
        width: 100%;
    }

    .instanceCol2 {
        margin-left: 0;
    }
}

@media screen and (max-width: 550px) {
    .mobileIcon {
        margin: 0 6px;
        height: 40px;
        min-width: 0;
        line-height: 24px;
        padding: 8px;
        width: 40px;
        border-radius: 50%;
    }
    .headerLinks {
        padding: 3px 0px;
    }
    
    .hideIcon {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .hideOnPhone {
        display: none !important;
    }

    .mainTable th.tabletKeep, .mainTable td.tabletKeep {
        display: none;
    }
    /* .mainTable td.phoneKeep th, .mainTable td.phoneKeep td */
    .mainTable th.phoneKeep, .mainTable td.phoneKeep  {
        display: table-cell;
    }
    
    .hideIcon {
        display: none;
    }
}

@media screen and (max-width: 450px) {
    .headerLinks .topContractButton i.mdi {
        display: block;
    }

    .headerLinks .topContractButton .ellipsedText {
        /* display: none; */
    }
    .headerLinks .topContractButton {
        /* max-width: 40px;
        min-width: 40px;
        padding: 0; */
    }
    .navigationInnerWrapper .contractsHolder span {
        font-size: .7em;
    }
    .dashboardTopFilters {
        display: none;
    }
    .navigationInnerWrapper .logoWrapper {
        display: none;
    }
    .headerLinks {
        margin: 0;
        padding: 3px 0;
    }

    .contractWrapper {
        width: auto;
    }
    
    .hideIcon {
        display: none;
    }
}

/* simulate responsive*/
table p {
    margin: auto;
}
.invisible {
    visibility: hidden;
}

.coverup {
    position: relative;
}

    .coverup:after {
        content: "";
        display: block;
        position: absolute;
        background: rgba(250, 250, 250, 0.4);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
.dragable {
    background: black;
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 20px;
}

.buttonbody {
    padding: 0 5px;
    border-radius: 50%;
    line-height: 1.42857143;
}
.minibutton {
    cursor: pointer;    
}
.buttonbody.disabled {
    color: rgba(0,0,0,.1);
}
.minibutton:not(.disabled):hover {
        background: #edf3f9;
}
.md-menu .minibutton:before {
    position: relative;
    left: -2px;
}

/* WBS */

.wbs-tree {
    margin: 0 15px 0 0;
}

.wbs-parent-toggle {
    padding-left: 15px;
}

.wbs-element-delete-confirm-msg {
    margin: 6px 0 6px 0; 
    font-weight: 400;
}

.wbs-confirm-delete-wrapper {
    cursor: auto;
}

.wbs-edit-element-wrapper {
    font-weight: 400;
}

.sov-item-title {
    background: #f7f9fc; 
    padding: 15px 15px 15px 15px;
    margin-bottom: 0;
    /* TODO: Get overflow to work properly. Also apply to nodes */
    /* white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
}

.sov-arrow-expand {
    margin-top: 5px;
    min-width: 11.7px;
}

.wbs-node-total {
    margin-right: 10px;
}

.wbs-item {
    padding: 5px 10px;
    margin: 10px 0;
}

.wbs-node-children-container {
    padding-left: 15px;
}

/* END WBS */

/* Contract Overview */

.contract-overview-status-label {
    text-transform: uppercase; 
    border-radius: 4px; 
    padding: 4px 8px;
    min-width: 100px;
    text-align: center;
    font-size: 1.2em;
    display: inline;
}

.contract-overview-break {
    background-color: rgba(43, 76, 100, 0.78);
    height: 3px;
    border-radius: 1px;
    margin-bottom: 20px;
}

.contract-overview-container {
    border-bottom: 3px solid #dcdcdc;
    padding-bottom: 25px;
    clear: both;
}

.contract-overview-name {
    margin-bottom: 0;
}

.contract-overview-flex-container {
    display: flex;
    flex-wrap: wrap;
    background: #edf3f9; 
    padding: 0 15px 15px;
}

.flexWrapper > * {
    display: flex; 
    flex-direction: column; 
    flex-wrap: wrap;
    min-width: 250px;
    max-width: 100%;
    flex: 1 0 auto;
    background: #edf3f9;
    padding: 10px;
    margin: 0 20px 20px 0;
}

.contract-overview-middle {
    width: 45%;
}

.contract-overview-middle>table {
    width: 100%;
}

.contract-overview-row-gap {
    flex: 0 1 auto; 
    width: 10%;
}

.contract-overview-summary {
    margin: 0 10px 20px 0;
    background: #fcfdfe;
    padding: 10px;
    color: black;
}

.contract-overview-top {
    width: 50%;
}

.contract-overview-flex-container>div {
    flex: 0 1 auto;
}

.contract-overview-summary-title {
    font-weight: bold;
    color: black;
    padding-bottom: 0;
}

.weatherData {
    display: flex; 
    flex-direction: column;
}

.weatherTable {
    width:100%;
    border-collapse: collapse;
    border-top: 2px solid lightgray;
    border-bottom:2px solid lightgray
}

.noWeatherData {
    display: flex;
    align-items: center;
    height: 75px;
}

.weatherTable th {
    text-align:center; 
    border-bottom: 2px solid lightgray;
    border-right: 2px solid lightgray; 
    font-weight: bold;
    font-size: 15px !important;
}

.weatherTable td {
    border-bottom: 2px solid lightgray; 
    border-right: 2px solid lightgray;
}

.dailyForecastTable {
    width:100%;
    border-collapse: collapse;
    border-top: 2px solid lightgray;
    border-bottom:2px solid lightgray
}

.dailyForecastTable td {
    text-align: center; 
    border-bottom: none;
}

.dailyForecastTable th {
    text-align:center; 
    border-bottom: 2px solid lightgray; 
    font-weight: bold;
    font-size: 15px !important;
}

.dailyForecastValues {
    text-align:center;
}

.weatherDataContainer {
    display: flex;
    justify-content: space-around;
}

.contract-overview-measure {
    width: 90%;
}

.contract-overview-divider {
    padding-top: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid transparent;
    border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0.12) rgba(0,0,0,0);
}

.contract-overview-item-row {
    height: 30px;
    color: black;
}

.contract-overview-item-value {
    float: right;
    color: black;
}

.contract-overview-chart-co-percentage-title {
    background: white;
    margin-bottom: 0;
    padding: 5px;
    text-align: center;
}

/* END Contract Overview */

.desktopView .table.customResponsive {
}


    .desktopView .table.customResponsive th, .desktopView .table.customResponsive td {
        display: table-cell !important;
    }

.tabletView {
    min-width: 480px !important;
    max-width: 1024px !important;
}
.tabletView .hideOnTablet {
    display: none;
}

    .tabletView .table.customResponsive th:not(.tabletKeep), .tabletView .table.customResponsive td:not(.tabletKeep) {
        display: none !important;
    }

    .tabletView .table.customResponsive th.tabletKeep, .tabletView .table.customResponsive td.tabletKeep {
        display: table-cell !important;
    }

.phoneView {
    min-width: 0 !important;
    max-width: 480px !important;
}
.phoneView .hideOnPhone {
    display: none;
}
    .phoneView .table.customResponsive th:not(.phoneKeep), .phoneView .table.customResponsive td:not(.phoneKeep) {
        display: none !important;
    }

    .phoneView .table.customResponsive th.phoneKeep, .phoneView .table.customResponsive td.phoneKeep {
        display: table-cell !important;
    }
/* md-select styling */
.selectdemoSelectHeader {
}

    .selectdemoSelectHeader .demo-header-searchbox {
        border: none;
        outline: none;
        height: 100%;
        width: 100%;
        padding: 0;
    }

    .selectdemoSelectHeader .demo-select-header {
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
        padding-left: 10.667px;
        height: 48px;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        width: auto;
        margin-top: -48px;
        width: 100%;
        position: fixed;
        background-color: #fff;
    }

.selectdemoSelectHeader .noOptionsSelect.demo-select-header { 
    margin-top: -84px;

}
input:disabled::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
}
.selectdemoSelectHeader md-content._md {
    max-height: 240px;
    margin-top: 48px;
    height: 240px;
}

.formElementTable.selectdemoSelectHeader md-content._md {
    margin-top: 40px;
}

.md-open-menu-container.md-leave, .md-open-menu-container.md-active {
    transition-duration: 1ms;
}

md-select-menu.md-default-theme md-content md-option[selected], md-select-menu md-content md-option[selected] {
    color: rgb(26,179,147) !important;
}

#floatingMenu {
    position: fixed;
    z-index: 15;
    right: 20px;
    top: 135px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    background: #edf3f9;
    border-radius: 5px;
    min-width: 253px;
    text-align: center;
    padding: 10px 0;
}
.hasTabs #floatingMenu {
    top: 70px;
}

#section-to-print {
    display: none;
}

    #section-to-print table p {
        display: inline-block;
        width: 100%;
        margin: 5px 0;
        white-space: pre-wrap;
    }

    #section-to-print table *.forceBlock > p {
        display: block;
    }

.pagebreakAfter {
    page-break-after: always;
    display: block !important;
    background: #f9f9f9;
}

.pagebreakBefore {
    page-break-before: always;
    display: block !important;
    background: #f9f9f9;
}

.pagebreakAfter::after {
    content: "Page Break";
    display: block;
    text-align: center;
    color: #bbb;
    font-size: 10px;
    border-bottom: 1px dashed #bbb;
}

.pagebreakBefore::before {
    content: "Page Break";
    display: block;
    text-align: center;
    color: #bbb;
    font-size: 10px;
    border-top: 1px dashed #bbb;
}

.remove-file {
    padding: 4px;
    margin: 4px 0 5px 0;
    line-height: 19.5px;
}

@media print {
    body > * {
        display: none !important;
        color: #000;
    }

    .md-dialog-is-showing {
        overflow: auto;
    }

    .forceBlock {
        display: block !important;
    }

    #section-to-print .gramm-problem, #section-to-print .grammar-problem, #section-to-print .wsc-grammar-problem {
        border-bottom: 0;
    }

    html, body {
        /* overflow: visible; */
        /* display: block !important; */
        /* width: auto !important; */
        /* float: none !important; */
        position: static !important;
        /* overflow: visible !important; */
        height: auto;
    }

    .table th {
        color: #000;
    }
    /*#section-to-print {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }*/
    #section-to-print {
        /* visibility: visible !important; */
        display: block !important;
        white-space: pre-wrap;
        /* width: auto !important; */
        /* float: none !important; */
        /* position: static !important; */
        /* overflow: visible !important; */
    }
        #section-to-print.contractOverview {
            white-space: normal;
        }
        #section-to-print.contractOverview .contract-overview-summary table {
            
            page-break-inside: avoid !important;
        }
        #section-to-print.contractOverview .contract-overview-summary, #section-to-print.contractOverview .contract-overview-summary table {
            position: relative;
        }
        #section-to-print .dashboardPrintWrapper, #section-to-print .emailPrint {
            white-space: normal;
        }

        #section-to-print span {
            display: inline;
        }

        #section-to-print table {
            align-self: center;
            margin: 0 auto;
            width: 100%;
        }

    /*#section-to-print {
        display: block !important;
        overflow: visible;
    }*/
    .pagebreakAfter, .pagebreakBefore {
        border-bottom: 0;
        display: block !important;
        background: transparent;
    }

        .pagebreakAfter::after, .pagebreakBefore::before {
            content: "";
            display: none;
        }

    a[href]:after {
        content: none !important;
    }
}

/*forces to print background images by default*/
* {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}

.mandatoryField {
    background-color: #e5e2e2;
    color: #777777;
    padding: 3px 5px;
    border-radius: 3px;
    min-width: 30px;
    text-align: center;
    display: inline-block;
}

.center {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.contentInnerWrapper .breadcrumb {
    top: 0;
}

/* TASK 6202*/
wfentityinstance form-layout form table.table.formTable tr td .annotation-field {
    margin-top: 40px;
}
/* end*/