.mat-mdc-select-panel.mdc-menu-surface--open {
  padding: 0;

  .search-field {
    z-index: 1;
    position: sticky;
    top: 0;
    background-color: var(--white);
    .cipo-input {
      width: 100%;
    }
  }
  .mat-mdc-option {
    .mdc-list-item__primary-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.p-0 {
      padding: 0;
    }
  }
}

.mat-mdc-select {
  &.hide-arrow .mat-mdc-select-arrow-wrapper {
    display: none;
  }
}
