.cipo-froala {
  &.error .fr-box {
    &:not(.minimal) {
      outline: 1px solid var(--warn-500);
    }

    &.minimal.fr-basic .fr-wrapper {
      border-bottom: 1px solid var(--warn-500) !important;
    }
  }

  .fr-box {
    &.minimal {
      .fr-second-toolbar,
      .fr-toolbar {
        display: none;
      }

      &.fr-box.fr-basic .fr-wrapper {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid var(--black);
      }

      .fr-view.fr-element {
        padding: $spacer-sm $spacer;
      }
    }

    .fr-code {
      background-color: var(--white);
      color: var(--black);
    }

    .fr-toolbar {
      background-color: var(--primary-50);

      .fr-more-toolbar {
        background-color: var(--primary-100);
        .fr-btn.fr-command {
          color: var(--black);
          &:hover {
            background-color: var(--primary-200);
          }

          &.fr-open:not(:hover):not(:focus):not(:active) {
            background-color: var(--primary-100);
          }

          svg path {
            fill: var(--black);
          }
        }
      }

      .fr-btn-grp {
        .fr-btn.fr-command {
          color: var(--black);
          &:hover {
            background-color: var(--primary-200);
          }

          &.fr-open:not(:hover):not(:focus):not(:active) {
            background-color: var(--primary-100);
          }

          svg path {
            fill: var(--black);
          }

          span.material-icons,
          span.material-symbols,
          span.material-icons-outlined,
          span.material-symbols-outlined {
            font-weight: normal;
            font-size: 24px;
            height: 24px;
            line-height: 1;
          }
        }
      }
    }

    .fr-second-toolbar {
      background-color: var(--primary-50);
    }

    &.fr-basic .fr-wrapper .fr-view.fr-element {
      font-size: mat.get-theme-typography($default-theme, body-1);
      color: var(--black);
      background-color: var(--white);
    }

    .fr-line-breaker {
      display: none;
    }
  }

  div.fr-view {
    hr {
      border-top: 2px solid var(--black);
    }

    table {
      border-collapse: initial;

      th {
        font-size: 12px;
        background-color: var(--primary-200);
      }

      td,
      th {
        color: black;
        border: 1px solid #eaeaea;

        &.fr-selected-cell {
          border: 1px solid var(--primary-900) !important;
        }
      }

      &.fr-alternate-rows {
        tbody tr:nth-child(2n) {
          background-color: var(--primary-100);
        }
      }
      --border-width: 1px;
      --black: #00066;

      &.fr-thick {
        --border-width: 2px;
      }

      &.fr-thin {
        --black: #c0c0c0;
      }

      &.fr-dashed-borders {
        td,
        th {
          border-style: dashed;
        }
      }

      &.fr-border-solid {
        --border-width: 1px;
        td,
        th {
          border-style: solid;
        }
      }

      &.fr-border-all {
        thead th,
        tbody td {
          border-bottom-width: var(--border-width);
          border-right-width: var(--border-width);
          border-top-width: 0;
          border-left-width: 0;
          border-color: var(--black);

          &:first-child {
            border-left-width: var(--border-width);
          }
        }
        thead {
          tr:first-child {
            th {
              border-top-width: var(--border-width);
            }
          }
        }
        &:not(:has(thead)) {
          tbody tr:first-child {
            td {
              border-top-width: var(--border-width);
            }
          }
        }
      }

      &.fr-border-top {
        thead th,
        tbody td {
          border-top-width: var(--border-width);
        }
      }

      &.fr-border-bottom {
        thead th,
        tbody td {
          border-bottom-width: var(--border-width);
        }
      }

      &.fr-border-left {
        thead th,
        tbody td {
          border-left-width: var(--border-width);
        }
      }

      &.fr-border-right {
        thead th,
        tbody td {
          border-right-width: var(--border-width);
        }
      }

      tbody tr td,
      thead tr th {
        --cell-black: $default--black;

        &.fr-thin {
          --cell-black: #c0c0c0;
        }

        &.fr-thick {
          --border-width: 2px;
        }

        &.fr-no-borders {
          border-width: 0;
        }

        &.fr-dashed-borders {
          border-style: dashed;
        }

        &.fr-border-solid {
          border-style: solid;
        }

        &.fr-border-top {
          border-top-width: var(--border-width);
        }

        &.fr-border-bottom {
          border-bottom-width: var(--border-width);
        }

        &.fr-border-left {
          border-left-width: var(--border-width);
        }

        &.fr-border-right {
          border-right-width: var(--border-width);
        }
      }
    }
  }
}

.fr-popup {
  z-index: 150 !important;
}

.fr-view.view-mode {
  .wsc-grammar-problem {
    border-bottom: none !important;
  }
}
