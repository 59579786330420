.collapsibleSvgTb {
    background-color: #777;
    color: white;
    cursor: pointer;
    border: none;
    text-align: left;
    outline: none;
    padding: 18px;
}

.contentSvgTb {
    overflow: hidden;
    display: block;
}

.parentRowSVGgantt {
    display: block;
}

#tooltip {
    background: cornsilk;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;

}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}