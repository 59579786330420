.border-radius--xs {
  border-radius: $spacer-xs;
}

.border-radius--md {
  border-radius: $spacer-md;
}

.border-radius--circle {
  border-radius: 50%;
}

.border-sm {
  border: 1px solid var(--primary-200);
}

.border--none {
  border: none;
}

.border-bottom--dashed {
  border-bottom: 1px dashed var(--primary-200);
}

.border-bottom--solid {
  border-bottom: 1px solid var(--mdc-filled-text-field-active-indicator-color);
}

.border-top--solid {
  border-top: 1px solid #ddd;
}

.border-bottom--lite {
  border-bottom: 1px solid #e0e0e0;
}

.border-all--solid {
  border: 1px solid #ddd;

  &.warn {
    border-color: $orange--primary-500;
  }
  &.new {
    border-color: $orange--primary-900;
  }
}

.cipo-card-container {
  border-radius: $spacer-xs;
  background-color: var(--white);
  padding: $spacer-md;
  box-shadow: 0px 0px 3px;
  &.full {
    height: 100%;
    overflow: hidden;

    &.overflow-auto {
      overflow: auto;
    }
  }
}
