/* 
These styles are copied from "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css".
The documentation says we should import this file, but I decided to copy it and adapted to our styles. 
There may be unused css code, and would be great to review them all later on.
All design changes of the dateTime picker should be done in this file
*/
.owl-dt-container {
  display: block;
  background: var(--white);
  color: var(--black);
  font: mat.get-theme-typography($default-theme, body-1);

  & * {
    box-sizing: border-box;
  }

  .owl-dt-container-row {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    &.owl-dt-calendar {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;

      .owl-dt-calendar-control {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 100%;
        padding: 0.5em;
        background-color: var(--primary-100);

        .owl-dt-calendar-control-content {
          -webkit-box-flex: 1;
          flex: 1 1 auto;
          display: -webkit-box;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;

          .owl-dt-calendar-control-button {
            padding: 0 0.8em;

            &:hover {
              background-color: var(--black);
            }
          }
        }
      }

      .owl-dt-calendar-main {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-flex: 1;
        flex: 1 1 auto;
        padding: 0 0.5em 0.5em;
        outline: 0;
      }
    }
  }
}
.owl-dt-calendar-view {
  display: block;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
.owl-dt-calendar-multi-year-view {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.owl-dt-calendar-multi-year-view .owl-dt-calendar-table {
  width: calc(100% - 3em);
}
.owl-dt-calendar-multi-year-view
  .owl-dt-calendar-table
  .owl-dt-calendar-header
  th {
  padding-bottom: 0.25em;
}
.owl-dt-calendar-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
  text-align: center;
  padding-bottom: 1em;
}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider {
  position: relative;
  height: 1px;
  padding-bottom: 0.5em;
}
.owl-dt-calendar-table
  .owl-dt-calendar-header
  .owl-dt-calendar-table-divider:after {
  content: "";
  position: absolute;
  top: 0;
  left: -0.5em;
  right: -0.5em;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
}
.owl-dt-calendar-table .owl-dt-calendar-cell {
  position: relative;
  height: 0;
  line-height: 0;
  text-align: center;
  outline: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.owl-dt-calendar-table {
  .owl-dt-calendar-cell-content {
    position: absolute;
    top: 5%;
    left: 5%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 90%;
    height: 90%;
    line-height: 1;
    border: 1px solid transparent;
    border-radius: 999px;
  }

  .owl-dt-calendar-cell:not(.owl-dt-calendar-cell-disabled) {
    .owl-dt-calendar-cell-content {
      cursor: pointer;
    }
  }
}
.owl-dt-calendar-table .owl-dt-calendar-cell-out {
  opacity: 0.2;
}
.owl-dt-calendar-table
  .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border-color: rgba(0, 0, 0, 0.4);
}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  color: var(--white);
  background-color: var(--primary-900);
}
.owl-dt-calendar-table
  .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.85);
}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled {
  :not(.owl-dt-calendar-cell-out) {
    opacity: 0.4;
  }
  cursor: default;
}
.owl-dt-calendar-table
  .owl-dt-calendar-cell-disabled
  > .owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected {
  opacity: 0.4;
}
.owl-dt-calendar-table
  .owl-dt-calendar-cell-disabled
  > .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border-color: rgba(0, 0, 0, 0.2);
}
.owl-dt-calendar-table
  .owl-dt-calendar-cell-active:focus
  > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.owl-dt-calendar-table
  :not(.owl-dt-calendar-cell-disabled):hover
  > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  background-color: rgba(0, 0, 0, 0.04);
}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range {
  background: var(--primary-200);
}
.owl-dt-calendar-table
  .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-from {
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
}
.owl-dt-calendar-table
  .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-to {
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
}
.owl-dt-timer {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 7em;
  padding: 0.5em;
  outline: none;
}
.owl-dt-timer-box {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  width: 25%;
  height: 100%;
}
.owl-dt-timer-content {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  margin: 0.2em 0;
}
.owl-dt-timer-content .owl-dt-timer-input {
  display: block;
  width: 2em;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: var(--primary-50);
  color: var(--primary-900);
  border-radius: 3px;
  outline: medium none;
  padding: 0.2em;
}
.owl-dt-timer-divider {
  display: inline-block;
  align-self: flex-end;
  position: absolute;
  width: 0.6em;
  height: 100%;
  left: -0.3em;
}
.owl-dt-timer-divider:before,
.owl-dt-timer-divider:after {
  content: "";
  display: inline-block;
  width: 0.35em;
  height: 0.35em;
  position: absolute;
  left: 50%;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: currentColor;
}
.owl-dt-timer-divider:before {
  top: 35%;
}
.owl-dt-timer-divider:after {
  bottom: 35%;
}
.owl-dt-control-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  background-color: transparent;
  color: inherit;
}
.owl-dt-control-button .owl-dt-control-button-content {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  outline: none;
}
.owl-dt-control-period-button .owl-dt-control-button-content {
  height: 1.5em;
  padding: 0 0.5em;
  border-radius: 3px;
  -webkit-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.owl-dt-control-period-button:hover > .owl-dt-control-button-content {
  background-color: rgba(0, 0, 0, 0.12);
}
.owl-dt-control-period-button .owl-dt-control-button-arrow {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin: 0.1em;
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition:
    transform 200ms ease,
    -webkit-transform 200ms ease;
}
.owl-dt-control-arrow-button .owl-dt-control-button-content {
  padding: 0;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
}
.owl-dt-control-arrow-button[disabled] {
  cursor: default;
}
.owl-dt-control-arrow-button svg {
  width: 50%;
  height: 50%;
  fill: currentColor;
}
.owl-dt-inline-container,
.owl-dt-popup-container {
  position: relative;
  width: 18.5em;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-inline-container .owl-dt-timer,
.owl-dt-popup-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-timer {
  width: 100%;
}
.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
  height: 20.25em;
}
.owl-dt-dialog-container {
  max-height: 95vh;
  margin: -1.5em;
}
.owl-dt-dialog-container .owl-dt-calendar {
  min-width: 250px;
  min-height: 330px;
  max-width: 750px;
  max-height: 750px;
}
.owl-dt-dialog-container .owl-dt-timer {
  min-width: 250px;
  max-width: 750px;
}
@media all and (orientation: landscape) {
  .owl-dt-dialog-container .owl-dt-calendar {
    width: 58vh;
    height: 62vh;
  }
  .owl-dt-dialog-container .owl-dt-timer {
    width: 58vh;
  }
}
@media all and (orientation: portrait) {
  .owl-dt-dialog-container .owl-dt-calendar {
    width: 80vw;
    height: 80vw;
  }
  .owl-dt-dialog-container .owl-dt-timer {
    width: 80vw;
  }
}
.owl-dt-container-buttons {
  display: -webkit-box;
  display: flex;
  width: 100%;
  height: 2em;
  color: var(--primary-900);
}
.owl-dt-container-control-button {
  width: 50%;
  height: 100%;
  border-radius: 0;

  &:first-child {
    background-color: mat.get-theme-color(
      $default-theme,
      background,
      selected-button
    );
  }

  &:last-child {
    background-color: var(--primary-900);
    color: var(--white);
  }
}
.owl-dt-container-control-button .owl-dt-control-button-content {
  height: 100%;
  width: 100%;
  -webkit-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.owl-dt-container-control-button:hover .owl-dt-control-button-content {
  background-color: rgba(0, 0, 0, 0.1);
}
.owl-dt-container-info {
  padding: 0 0.5em;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.owl-dt-container-info .owl-dt-container-range {
  outline: none;
}
.owl-dt-container-info .owl-dt-container-range .owl-dt-container-range-content {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 0.5em 0;
}
.owl-dt-container-info .owl-dt-container-range:last-child {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.owl-dt-container-info .owl-dt-container-info-active {
  color: var(--primary-900);
}
.owl-dt-container-disabled,
.owl-dt-trigger-disabled {
  opacity: 0.35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: default !important;
}
.owl-dt-timer-hour12 {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  color: var(--primary-900);
}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box {
  border: 1px solid currentColor;
  border-radius: 2px;
  -webkit-transition: background 200ms ease;
  transition: background 200ms ease;
}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box .owl-dt-control-button-content {
  width: 100%;
  height: 100%;
  padding: 0.5em;
}
.owl-dt-timer-hour12
  .owl-dt-timer-hour12-box:focus
  .owl-dt-control-button-content,
.owl-dt-timer-hour12
  .owl-dt-timer-hour12-box:hover
  .owl-dt-control-button-content {
  background: var(--primary-900);
  color: var(--white);
}
.owl-dt-calendar-only-current-month .owl-dt-calendar-cell-out {
  visibility: hidden;
  cursor: default;
}
.owl-dt-inline {
  display: inline-block;
}
.owl-dt-control .owl-dt-control-content {
  outline: none;
}
.owl-dt-control:focus > .owl-dt-control-content {
  background-color: rgba(0, 0, 0, 0.12);
}
.owl-dt-control:not(:-moz-focusring):focus > .owl-dt-control-content {
  box-shadow: none;
}
.owl-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
