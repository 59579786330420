.cipo-menu {
  .mat-mdc-menu-item {
    &:has(.mat-icon) {
      padding: 0 $spacer-sm;
      .mat-icon {
        margin: 0;

        &.end-icon {
          margin-left: $spacer;
        }
      }
    }
  }
}

.reversed-menu {
  &.mat-mdc-menu-panel {
    .mat-mdc-menu-item {
      flex-direction: row-reverse;

      .mat-mdc-menu-submenu-icon {
        transform: rotate(180deg);
      }

      //   this breaks the spacing on the menu if there is no nested menus. Therefor, we will add it when will make available those menu items that are in dev
      //   &:not(:has(.mat-mdc-menu-submenu-icon)) {
      //     .mat-mdc-menu-item-text {
      //       margin-left: 24px;
      //     }
      //   }

      .mat-icon {
        margin-right: 0;
        margin-left: 24px;
      }
    }
  }
}

// this is a fix to a problem where the height here is set to 48px and does not inherit the parent height
.mat-mdc-menu-item .mat-mdc-menu-ripple {
  height: inherit !important;
}
