/*
    After including mat.icon-button-density(-2) in core scss file, the default button size changed from 48px to 40px.
    This way, we don't need icon-btn--md. At least, not with these values.
*/

// I'll leave the code for further customizations
.cipo-button {
  --icon-button-size: 40px;

  &.icon-btn--md {
    --icon-button-size: 32px;
  }

  &.mat-mdc-icon-button {
    width: var(--icon-button-size);
    height: var(--icon-button-size);
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .mat-mdc-button-touch-target,
    .mat-mdc-button-ripple,
    .mat-mdc-focus-indicator {
      width: var(--icon-button-size);
      height: var(--icon-button-size);
    }
  }
}

// this is a fix to a problem where the height here is set to 48px and does not inherit the parent height
.mat-mdc-button .mat-mdc-button-touch-target {
  height: inherit !important;
}
