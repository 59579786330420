// Imports
@import "./font-size";
@import "./font-weight";
@import "../../colors/colors";

@mixin cipo-mixin--typography($font-size, $font-weight, $color) {
  font-size: $font-size;
  font-weight: $font-weight;
  //   color: $color;
  margin: 0;
  line-height: 150%;
}

// Typography generator skeleton
@mixin cipo-create-typography($name, $font-size, $font-weight, $color) {
  @if $name ==
    "p" or
    $name ==
    "h1" or
    $name ==
    "h2" or
    $name ==
    "h3" or
    $name ==
    "h4" or
    $name ==
    "h5" or
    $name ==
    "h6"
  {
    #{$name} {
      @include cipo-mixin--typography($font-size, $font-weight, $color);
    }
  } @else {
    .cipo-text--#{$name} {
      @include cipo-mixin--typography($font-size, $font-weight, $color);
    }
  }
}

/*
//  nth($value, 1) - Typography name,
//  nth($value, 2) - Typography size,
//  nth($value, 3) - Typography weight,
//  nth($value, 4) - Typography color
*/
@mixin generate-typography($typography...) {
  @each $value in $typography {
    @include cipo-create-typography(
      nth($value, 1),
      nth($value, 2),
      nth($value, 3),
      nth($value, 4)
    );
  }
}

// Generate fonts classes
// Colors are commented in cipo-mixin--typography
.cipo,
.cipo-dialog {
  @include generate-typography(
    (h1, $font-size-h1, $font-weight-bold, $cipo-black-900),
    (h2, $font-size-h2, $font-weight-bold, $cipo-black-900),
    (h3, $font-size-h3, $font-weight-medium, $cipo-black-900),
    (h4, $font-size-h4, $font-weight-medium, $cipo-black-900),
    (h5, $font-size-h5, $font-weight-medium, $cipo-black-900),
    (h6, $font-size-h6, $font-weight-medium, $cipo-black-900),
    (regular-lg, $font-size-lg, $font-weight-regular, $cipo-black-700),
    (regular-md, $font-size-md, $font-weight-regular, $cipo-black-700),
    (regular-sm, $font-size-sm, $font-weight-regular, $cipo-black-700),
    (medium-xl, $font-size-xl, $font-weight-medium, $cipo-black-700),
    (medium-lg, $font-size-lg, $font-weight-medium, $cipo-black-700),
    (medium-md, $font-size-md, $font-weight-medium, $cipo-black-700),
    (medium-sm, $font-size-sm, $font-weight-medium, $cipo-black-700),
    (bold-lg, $font-size-lg, $font-weight-bold, $cipo-black-700),
    (bold-md, $font-size-md, $font-weight-bold, $cipo-black-700),
    (bold-sm, $font-size-sm, $font-weight-bold, $cipo-black-700),
    (label, $font-size-xs, $font-weight-medium, $cipo-black-700),
    (p, $font-size-md, $font-weight-regular, $cipo-black-900)
  );
}

.text--ellipsed {
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text--clip {
  overflow: hidden;
  width: auto;
  text-overflow: clip;
  white-space: nowrap;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--lowercase {
  text-transform: lowercase;
}

.text--center {
  &,
  & > * {
    text-align: center;
  }
}

.text--end {
  text-align: end;
}

.text-size--label {
  font-size: 11px;
  line-height: 1;
  margin-bottom: 0;
  color: var(--primary-500);

  &.error {
    color: var(--warn-700);
  }
}

.text--bold {
  font-weight: bold;
}

.text--pre-line {
  white-space: pre-line;
}

.text--pre {
  white-space: pre;
}

.hint {
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  margin: 0;
}

.small-info-text {
  font-size: 13px;
  color: #939393;
}

.small-info-icon {
  font-family: "Material Symbols Outlined";
  font-size: 17px;
}
