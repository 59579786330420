$spacer: 1rem; // in pixel: 16px
 
$spacer-xs: calc($spacer * 0.25); // in pixel: 4px
$spacer-sm: calc($spacer * 0.5); // in pixel: 8px
$spacer-md: calc($spacer * 0.75); // in pixel: 12px
$spacer-lg: calc($spacer * 1.25); // in pixel: 20px
$spacer-xl: calc($spacer * 1.5); // in pixel: 24px
$spacer-xxl: calc($spacer * 2); // in pixel: 32px
$spacer-3xl: calc($spacer * 3); // in pixel: 64px

$spaces: (
  "-0": 0,
  "-auto": auto,
  "-xs": $spacer-xs,
  "-sm": $spacer-sm,
  "-md": $spacer-md,
  "": $spacer,
  "-lg": $spacer-lg,
  "-xl": $spacer-xl,
  "-xxl": $spacer-xxl,
  "-3xl": $spacer-3xl,
);
.cipo,
.cipo-dialog {
  @each $key, $value in $spaces {
    .m-t#{$key} {
      margin-top: $value;
    }

    .m-b#{$key} {
      margin-bottom: $value;
    }

    .m-l#{$key} {
      margin-left: $value;
    }

    .m-r#{$key} {
      margin-right: $value;
    }

    .m-a#{$key} {
      margin: $value;
    }

    .m-x#{$key} {
      margin-left: $value;
      margin-right: $value;
    }

    .m-y#{$key} {
      margin-top: $value;
      margin-bottom: $value;
    }
  }

  @each $key, $value in $spaces {
    .p-t#{$key} {
      padding-top: $value;
    }

    .p-b#{$key} {
      padding-bottom: $value;
    }

    .p-l#{$key} {
      padding-left: $value;
    }

    .p-r#{$key} {
      padding-right: $value;
    }

    .p-a#{$key} {
      padding: $value;
    }

    .p-x#{$key} {
      padding-left: $value;
      padding-right: $value;
    }

    .p-y#{$key} {
      padding-top: $value;
      padding-bottom: $value;
    }
  }
}
