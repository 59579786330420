.attachments-field {
  .mat-mdc-chip-set {
    display: block;
    .mat-mdc-standard-chip {
      height: 40px;

      .mat-mdc-chip-action-label {
        text-wrap: nowrap;
      }
    }

    .mdc-evolution-chip__icon--primary {
      height: 24px;
      width: 24px;
      border-radius: 0;
    }
  }
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input {
    display: none;
  }
}

.attachments-chip-set {
  .mat-mdc-standard-chip {
    padding-right: $spacer-sm;
    .mat-mdc-chip-action-label {
      text-wrap: nowrap;
    }

    .mdc-evolution-chip__icon--primary {
      height: 24px;
      width: 24px;
      border-radius: 0;
    }

    &.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action
      .mdc-evolution-chip__action--trailing {
      padding-right: 0;
    }
  }
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
}
