@font-face {
    font-family: 'la_belle_auroreregular';
    src: url('labelleaurore-regular-webfont.woff2') format('woff2'), url('labelleaurore-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.signatureBelle {
    font-family: 'la_belle_auroreregular', 'La Belle Aurore', serif;
}
.locked {
    touch-action: none;
}