/*
 * angular-table-resize
 * https://github.com/tympanix/angular-table-resize
 * - minimal stylesheet
 */

 table.rz-table {
   /**/table-layout: fixed;
   border-collapse: collapse;
 }

table.rz-table th {
    position: relative;
    min-width: 50px;
}

table.rz-table th .rz-handle, th .rz-handle {
    width: 10px;
    height: 70%;
    position: absolute;
    top: 15%;
    right: 0px;
    cursor: ew-resize !important;
    border-right: 1px dotted #9e9e9e;
}

table.rz-table th .rz-handle.rz-handle-active {
    border-right: 2px dotted transparent;
}
.rz-handle {
  border-right: 2px dotted transparent;
}
table.rz-table th {
    border-right: 1px solid #d8d3d3;
}
table.rz-table th.column-active {
    border-right: 1px dotted #000!important;
}
.row-active {
    
    border-right: 1px dotted #000;
}