@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('../fonts/glyphicons-halflings-regular.eot');
    src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.glyphicon-time:before {
    content: "\e023";
}

.glyphicon-chevron-left:before {
    content: "\e079";
}

.glyphicon-chevron-right:before {
    content: "\e080";
}

.glyphicon-chevron-up:before {
    content: "\e113";
}

.glyphicon-chevron-down:before {
    content: "\e114";
}

.glyphicon-calendar:before {
    content: "\e109";
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.collapse {
    display: none;
}

    .collapse.in {
        display: block;
    }

.dropdown-menu {
    position: absolute;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}



.datepicker, .timepicker {
    min-width: 230px;
}
.bootstrap-datetimepicker-widget{
    z-index: 100 !important;
}
.datepicker span {
    display: block;
    padding: 5px 0;
    width: 100%;
    height: auto;
    line-height: 20px;
    float: none;
    margin: 0px;
    cursor: pointer;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.datepicker th.next, .datepicker th.prev {
    font-size: 16px;
}
.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #4e969c;
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.bootstrap-datetimepicker-widget table td.active.today:before {
    border-bottom-color: #fff;
}
.bootstrap-datetimepicker-widget table td.today {
    position: relative;
}
.bootstrap-datetimepicker-widget table td.day {
    cursor:pointer;
    cursor: hand;
}
.bootstrap-datetimepicker-widget table td.day:hover {
    background: #f5f5f5;
}

.bootstrap-datetimepicker-widget table td.today:before {
    content: '';
    display: inline-block;
    border: solid transparent;
    border-width: 0 0 7px 7px;
    border-bottom-color: #337ab7;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
}
.datepicker-months thead {
    display: table-header-group;
}
.datepicker span.month, .datepicker span.year, .datepicker span.decade {
    display: inline-block;
    padding: 5px;
    width: 25%;
    margin: 0px;
}
.datepicker span.decade {
    width: 33%;
    font-size: 11px;
}
.datepicker td span:empty{
    display: none;
}
.datepicker span.active, .datepicker span.active:focus, .datepicker span.active:hover, .datepicker span.active:active {
    background-color: #4e969c;
    box-shadow: none;
}
.bootstrap-datetimepicker-widget .timepicker-hour, .bootstrap-datetimepicker-widget .timepicker-minute, .bootstrap-datetimepicker-widget .timepicker-second,
.bootstrap-datetimepicker-widget table .hour, .bootstrap-datetimepicker-widget table .minute {
    
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    cursor: pointer;
    cursor: hand;
    height: 44px;
    line-height: 44px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.bootstrap-datetimepicker-widget .timepicker-hour, .bootstrap-datetimepicker-widget .timepicker-minute, .bootstrap-datetimepicker-widget .timepicker-second {
    width: 100%;
    display: inline-block;
    margin: 0;
}
.bootstrap-datetimepicker-widget .timepicker-hour:hover, .bootstrap-datetimepicker-widget .timepicker-minute:hover, 
.bootstrap-datetimepicker-widget .timepicker-second:hover,
.bootstrap-datetimepicker-widget table .hour:hover, .bootstrap-datetimepicker-widget table .minute:hover

{
    background: #f5f5f5;
}
.bootstrap-datetimepicker-widget table {
    width: 100%;
}
.timepicker-picker table td {
    text-align: center;
}
.btn.btn-primary, .btn.btn-primary:hover, 
.btn.btn-primary:focus, .btn.btn-primary:active, 
.btn.btn-primary.active, .btn.btn-primary:active:focus, 
.btn.btn-primary:active:hover, .btn.btn-primary.active:focus, 
.btn.btn-primary.active:hover, .open > .btn.btn-primary.dropdown-toggle, 
.open > .btn.btn-primary.dropdown-toggle:focus, .open > .btn.btn-primary.dropdown-toggle:hover, 
.navbar .navbar-nav > li > a.btn.btn-primary, .navbar .navbar-nav > li > a.btn.btn-primary:hover, 
.navbar .navbar-nav > li > a.btn.btn-primary:focus, .navbar .navbar-nav > li > a.btn.btn-primary:active, 
.navbar .navbar-nav > li > a.btn.btn-primary.active, .navbar .navbar-nav > li > a.btn.btn-primary:active:focus, 
.navbar .navbar-nav > li > a.btn.btn-primary:active:hover, .navbar .navbar-nav > li > a.btn.btn-primary.active:focus, 
.navbar .navbar-nav > li > a.btn.btn-primary.active:hover, .open > .navbar .navbar-nav > li > a.btn.btn-primary.dropdown-toggle, 
.open > .navbar .navbar-nav > li > a.btn.btn-primary.dropdown-toggle:focus, .open > .navbar .navbar-nav > li > a.btn.btn-primary.dropdown-toggle:hover {
    background-color: #4e969c;
    border: 0;
}
.accordion-toggle a:hover {
    background-color: #4e969c!important;
}
.timepicker-picker .btn:not(.btn-primary) {
    display: inline-block;
    border: 0px solid transparent;
    background-color: transparent;
    color: #999999;
    box-shadow: none;
    border-radius: 50%;
    margin-top: 0;
    padding-top: 10px;
}
.timepicker-picker .btn:not(.btn-primary):hover {
    background: #f5f5f5;
}

.accordion-toggle {
    
}
.accordion-toggle a{
    width: 100%;
    display: block;
    text-align: center;
    font-size: 1.3em;
    padding: 7px 0 3px;
}
.accordion-toggle .table-condensed td{
    width: 100%;
    
}

