
.navigationWrapper {
    background-image: linear-gradient(to left, #17abb6, #0d5e65);
    background: #e9eced;
}
.userInfoWrapper, .footer {
    background-image: linear-gradient(to left, #17abb6, #0d5e65);
    line-height: 1.1em;
    background: #fff;
    font-size: 10px;
    color: #bbb;
}

.pageHeading h1 {
    color: rgba(0, 0, 0, 0.87);
}

th {
    color: #757575;
}

.md-button.btn-primary {
    color: white;
    background-color: #feb302;
}