.angular-ui-tree {
}

.angular-ui-tree-empty, .angular-ui-tree-dropzone {
    min-height: 100px;
    border: 3px dashed #dddddd;
    color: #bbb;
    /* background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
    background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
    background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
    background-size: 60px 60px;
    background-position: 0 0, 30px 30px;*/
}

.angular-ui-tree-empty {
    pointer-events: none;
}

.angular-ui-tree-nodes {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.angular-ui-tree-nodes .angular-ui-tree-nodes {
    padding-left: 20px;
}

.angular-ui-tree-node, .angular-ui-tree-placeholder {
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 20px;
    line-height: 20px;
}

.angular-ui-tree-hidden {
    display: none;
}

.angular-ui-tree-placeholder {
    margin: 10px 0px;
    padding: 0;
    min-height: 30px;
}

.angular-ui-tree-handle {
    cursor: move;
    text-decoration: none;
    font-weight: bold;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 20px;
    line-height: 20px;
}

.angular-ui-tree-drag {
    position: absolute;
    pointer-events: none;
    z-index: 999;
    opacity: .8;
}

.angular-ui-tree-drag .tree-node-content {
    margin-top: 0;
}
/* apppp */


/*.md-icon-button {
    margin-right: 8px;
} */

li .angular-ui-tree-handle {
    border: 1px solid rgba(0,0,0,0.12);
    padding: 10px 10px 10px 35px;
    /* border-bottom: 0; */
    border-left-width: 2px;
    border-right-width: 2px;
    padding-right: 80px;
}
li li .angular-ui-tree-handle {
    background: rgba(0,0,0,0.12);
    border: 1px solid white;
}
li li li .angular-ui-tree-handle {
    background: rgba(0,0,0,0.22);
}

.angular-ui-tree-handle:hover {
    color: #438eb9;
    background: #f4f6f7;
    border-color: #dce2e8;
}

.angular-ui-tree-placeholder {
    background: #f0f9ff;
    border: 2px dashed #bed2db;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

tr.angular-ui-tree-empty {
    height:100px
}

.group-title {
    background-color: #687074 !important;
    color: #FFF !important;
}


/* --- Tree --- */
.tree-node {
    border: 1px solid #dae2ea;
    background: #f8faff;
    color: #7c9eb2;
}

.nodrop {
    background-color: #f2dede;
}

.tree-node-content {
    margin: 10px;
}
.tree-handle {
    padding: 10px;
    background: #428bca;
    color: #FFF;
    margin-right: 10px;
}

.angular-ui-tree-handle:hover {
}

.angular-ui-tree-placeholder {
    background: #f0f9ff;
    border: 2px dashed #bed2db;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* custom */
.iconWrapper {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 19px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 6px;
    left: 10px;
    color: #868686;
}
/* .menuFolder:before {
    content: "\f07b";
    position: absolute;
    top: 10px;
    left: 30px;
}*/
.menuFolder>.iconWrapper {
    left: 30px;
}
.menuFolder>.angular-ui-tree-handle {
    padding-left: 55px;
    padding-right: 120px;
}
.collapseMenuToggle {
    position: absolute;
    top: 10px;
    left: 0px;
    width: 30px;
    text-align: center;
}
.menuActions .md-icon-button {
    position: absolute;
    right: 125px;
    top: 50%;
    margin: 0;
    margin-top: -20px !important;
}
.multilevelTree .menuActions .md-icon-button {
    top: 1px;
    margin-top: 0 !important;
}
.menuActions.formBtns .md-icon-button {
    top: 25px;
}
.menuActions .md-icon-button:first-child {
    right: 5px;
}
.menuActions .md-icon-button:nth-child(2) {
    right: 45px;
}
.menuActions .md-icon-button:nth-child(3) {
    right: 85px;
}
.loaderContainer {
    top: 10px; 
    position: absolute; 
    z-index: 10; 
    right: 10px;
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.6);
}
.loadingChildren {
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: auto;
    height: auto;
}
.loadingChildren .sk-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -11px;
    margin-left: -11px;
    
}
.loadingSiblings .sk-spinner{
    display: none;
}