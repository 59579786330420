.cipo-icon {
  --size: 24px;

  &.cipo-icon--sm {
    --size: 16px;
  }

  &.cipo-icon--md {
    --size: 20px;
  }

  &.cipo-icon--xl {
    --size: 32px;
  }

  &.cipo-icon--big {
    --size: 72px;
  }

  &.mat-icon {
    height: var(--size);
    width: var(--size);
    font-size: var(--size);
  }

  &.mat-icon.mat-icon-inline {
    line-height: 100%;
  }
}

p .mat-icon.mat-icon-inline {
  line-height: 100%;
}
