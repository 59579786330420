$line-height-sm: 100%;
$line-height-md: 120%;
$line-height-lg: 150%;

$line-heights: (
  "sm": $line-height-sm,
  "md": $line-height-md,
  "lg": $line-height-lg,
);

@each $key, $value in $line-heights {
  .cipo,
  .cipo-dialog {
    .cipo-text-height--#{$key} {
      line-height: $value;
    }
  }
}
