$disabled-color: rgba(0, 0, 0, 0.8);

.cipo-input {
  &.mat-mdc-form-field {
    //   hide the element below form-field underline
    &.hide-subscript .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    &.hide-underline .mdc-line-ripple {
      display: none;
    }

    &.cipo-input--sm {
      font-size: 14px;

      .mat-mdc-form-field-infix {
        padding-bottom: $spacer-xs;
        min-height: auto;
      }
    }

    &.p-0 .mat-mdc-text-field-wrapper {
      padding: 0;
    }

    .mdc-text-field--filled {
      &:not(.mdc-text-field--disabled),
      .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
      }

      &.mdc-text-field--disabled {
        .mdc-text-field__input {
          color: $disabled-color;
        }

        .mat-mdc-form-field-icon-suffix mat-icon {
          color: $disabled-color;
        }
      }
    }

    .mdc-text-field .mdc-floating-label {
      &:not(.mdc-floating-label--float-above) {
        transform: translateY(-12%);
      }

      @extend .label;
    }
  }
}

.cipo-form {
  .mat-mdc-form-field {
    @extend .cipo-input;
  }
}

.mat-mdc-form-field {
  --mdc-outlined-text-field-label-text-color: var(--black);
}

.hint-placeholder {
  margin-bottom: 22px;
}
