.tribute-container {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 1010;
  border: 1px solid var(--primary-200);
}
.tribute-container ul {
  margin: 0;
  margin-top: 2px;
  padding: 0;
  list-style: none;
  background: var(--primary-50);
  color: var(--black);
}
.tribute-container li {
  padding: 5px 5px;
  cursor: pointer;
}
.tribute-container li.highlight,
.tribute-container li:hover {
  background: var(--primary-100);
}
.tribute-container li span {
  font-weight: bold;
}
.tribute-container li.no-match {
  cursor: default;
}
.tribute-container .menu-highlighted {
  font-weight: bold;
}

.tag-user {
  color: var(--primary-900);
  font-weight: bold;
  border-radius: 3px;
  padding: 2px 4px;
  background-color: var(--primary-100);
  cursor: pointer;

  &[id]::before {
    content: "@";
  }
}

.parse-variables {
  color: var(--primary-900);
  font-weight: bold;
  text-decoration: underline;
  border-radius: 3px;
  padding: 2px 4px;
  background-color: var(--primary-100);
  cursor: pointer;

  .no-print {
    display: inline;
  }

  .print {
    display: none;
  }

  @media print {
    .no-print {
      display: none !important;
    }

    .print {
      display: inline !important;
    }
  }
}
