.test1 md-toolbar.md-default-theme:not(.md-menu-toolbar), md-toolbar:not(.md-menu-toolbar) {
    background-color: rgb(43, 76, 100);
}
/*.test1 .md-button, */
/* .test1 .pageHeading h1, h2, h1, h3 {
    color: rgba(0,0,0,0.87);
} */
md-tabs.md-default-theme .md-tab.md-active, md-tabs .md-tab.md-active, 
md-tabs.md-default-theme .md-tab.md-active md-icon, md-tabs .md-tab.md-active md-icon, 
md-tabs.md-default-theme .md-tab.md-focused, md-tabs .md-tab.md-focused, 
md-tabs.md-default-theme .md-tab.md-focused md-icon, md-tabs .md-tab.md-focused md-icon
{
    color: rgb(43, 76, 100) !important;
}

.transitionHasConditionsIcon {
    background: url(images/condition_icon_grey.png) no-repeat center center;
    width: 24px;
    height: 24px;
    /* position: absolute; */
    background-size: cover;
    /* top: 29px; */
    /* left: 250px; */
    z-index: 9999;
    position: relative;
    display: inline-block;
}
.inline-block{
    display: inline-block;
}
.draftInApproval {
    color: #ff6f00;
}
.warningTopBorder {
    border-top: 2px #f5bd00 solid;
}
.successBorderTop {
    border-top: 2px solid #54b095;
}
.successBorderLeft {
    border-left: 2px solid #54b095;
}
.successBorderRight {
    border-right: 2px solid #54b095;
}
td.successBackground {
    border-top: 0 !important;
}
.successBorderBottom {
    border-bottom: 2px solid #54b095;
}
.successBorderTop td {
    border-top: 0 !important;
}
.successBorderBox {
    border: 1px solid #54b095;
}
.successText,.successText:before, .successBorderBox {
    color: #54b095 !important;
}
.successBorderBoxDahed {
    border: 2px dashed #54b095;
}
.warningBox, .warningAnything {
    background-color: #f5bd00;
}
.warningBorderBox {
    border: 1px solid #f5bd00 !important;
}
.mainTable tfoot {
    border-top: 2px solid #f5bd00;
    background-color: rgba(255,255,204,.5);
}
.warningText {
    color: #f5bd00 !important;
}
.warning-msg {
    background-color: #f5bd00 !important;
}
.permissionsWrapper.selectedPermission {
    border: 1px solid #f5bd00;
}
.dangerWrapper {
    background: #f5bd00 !important;
}
.activeItem {
    background: #f5bd00;
}
.hasYBackground {
    background: #f5bd00;
}
.currentPeriodWrapper {
    border: 1px solid #f5bd00 !important;
}

md-input-container.md-default-theme.md-input-invalid .md-char-counter, md-input-container.md-input-invalid
.md-char-counter, md-input-container.md-default-theme.md-input-invalid .md-input-message-animation,
md-input-container.md-input-invalid .md-input-message-animation, md-input-container.md-default-theme.md-input-invalid label,
md-input-container.md-input-invalid label, md-input-container.md-default-theme .md-input-message-animation, md-input-container .md-input-message-animation, md-input-container.md-default-theme .md-input-messages-animation, md-input-container .md-input-messages-animation {
    color: #ea5768 !important;
}
.dashboardDraftText {
    color: #9650ec;
}
.dashboardOverdueText {
    color: #B05470;
}

.notificationsNo {
    background-color: #54b095;
}

.md-toolbar-tools {
    height: 54px;
    max-height: 54px;
}
md-toolbar {
    min-height: 54px;
}
.md-toolbar-tools h3 {
    font-size: .8em;
    line-height: .8em;
}
.mainTable.fr-alternate-rows tbody tr:nth-child(2n+1) {
    background: #fff;
}
.progressHolder {
    position: relative;
    display: block;
}
.progressHolder::after {
    position: absolute;
    width: 100%;
    height: 4px;
    color: red;
    left: 0;
    content: "";
    display: block;
    top: 50%;
    margin-top: -2px;
}
.progressBackground::after {
    background: #ccc;
}
.progressForeground::after {
    background: #54b095;
}

.whiteBackground {
    background-color: white !important;
}
.whiteText {
   color: white !important;
}