.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.height--auto {
  height: auto;
}

.height--fit-content {
  height: fit-content;
}

.layout-height {
  height: calc(100vh - 55px);
}

.width--auto {
  width: auto;
}

.overflow--scroll {
  overflow: scroll;
}

.overflow-x--scroll {
  overflow-x: scroll;
}

.overflow-y--scroll {
  overflow-y: scroll;
}

.overflow-y--auto {
  overflow-y: auto;
}

.overflow--hidden {
  overflow: hidden;
}

.display--none {
  display: none;
}

.display--block {
  display: block;
}

.cursor--default {
  cursor: default;
}
.cursor--pointer {
  cursor: pointer;
}

.cursor--help {
  cursor: help;
}

.cursor--move {
  cursor: move;
}

.height--auto {
  height: auto;
}

.width--auto {
  width: auto;
}

.position--relative {
  position: relative;
}

.position--absolute {
  position: absolute;
}

.position--fixed {
  position: fixed;
}

.position--sticky {
  position: sticky;
}

.overflow--auto {
  overflow: auto;
}
