.mat-mdc-paginator {
  &.compact {
    .mat-mdc-paginator-range-label {
      margin: 0;
    }
  }

  &.hidden {
    display: none;
  }
}

// possibility to make the paginator compact from the component that uses cipo-table
.compact-paginator {
  .mat-mdc-paginator .mat-mdc-paginator-range-label {
    margin: 0;
  }
}
