@media print {
  body > * {
    display: none !important;
    color: #000;
  }

  .md-dialog-is-showing {
    overflow: auto;
  }

  .fr-view hr {
    page-break-after: auto !important;
  }

  .forceBlock {
    display: block !important;
  }

  #section-to-print .gramm-problem,
  #section-to-print .grammar-problem,
  #section-to-print .wsc-grammar-problem {
    border-bottom: 0;
  }

  html,
  body {
    /* overflow: visible; */
    /* display: block !important; */
    /* width: auto !important; */
    /* float: none !important; */
    position: static !important;
    /* overflow: visible !important; */
    height: auto;
  }

  .table th {
    color: #000;
  }

  .fr-view table td,
  .fr-view table th {
    border-width: 0;
  }

  .fr-view .gramm-problem,
  .fr-view .grammar-problem,
  .fr-view .wsc-grammar-problem {
    border-bottom: 0;
  }
  /*#section-to-print {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }*/
  #section-to-print {
    /* visibility: visible !important; */
    display: block !important;
    white-space: pre-wrap;
    /* width: auto !important; */
    /* float: none !important; */
    /* position: static !important; */
    /* overflow: visible !important; */
  }
  #section-to-print.contractOverview {
    white-space: normal;
  }
  #section-to-print.contractOverview .contract-overview-summary table {
    page-break-inside: avoid !important;
  }
  #section-to-print.contractOverview .contract-overview-summary,
  #section-to-print.contractOverview .contract-overview-summary table {
    position: relative;
  }
  #section-to-print .dashboardPrintWrapper,
  #section-to-print .emailPrint {
    white-space: normal;
  }

  #section-to-print span {
    display: inline;
  }

  #section-to-print table {
    align-self: center;
    margin: 0 auto;
    width: 100%;
  }

  /*#section-to-print {
        display: block !important;
        overflow: visible;
    }*/
  .pagebreakAfter,
  .pagebreakBefore {
    border-bottom: 0;
    display: block !important;
    background: transparent;
  }

  .pagebreakAfter::after,
  .pagebreakBefore::before {
    content: "";
    display: none;
  }

  a[href]:after {
    content: none !important;
  }
}
