@import "./../abstracts/spacings";
@import "./../colors/default-palette";

.cipo-dialog {
  .mdc-dialog__surface {
    padding: $spacer-xl 0;
  }

  &.classic {
    .mdc-dialog__surface {
      padding: 0;

      .mdc-dialog__title {
        padding: 0 0 0 10px;
        background-color: var(--primary-900);
        color: var(--white);

        &::before {
          display: none;
        }
      }

      .mdc-dialog__actions {
        z-index: 1;
        border-radius: $spacer-sm $spacer-sm 0 0;
        box-shadow: 0px 2px 10px;
      }
    }
  }

  &.full-height {
    &.cdk-overlay-pane {
      max-width: 100%;
    }
    .mat-mdc-dialog-content {
      max-height: 100%;
    }
  }

  //   TODO: Remove after migrating all project to Angular
  @each $key, $color in $default-colors--light {
    .cipo-bg--#{$key} {
      background-color: $color;
    }

    .cipo-text--#{$key} {
      color: $color;
    }

    .cipo-bg-hover--#{$key} {
      &:hover {
        background-color: $color;
      }
    }
  }
}
