$font-size-xs: 10px;
$font-size-sm: 12px;
$font-size-md: 14px; // Default font size
$font-size-lg: 16px;
$font-size-xl: 18px;
$font-size-h6: 18px;
$font-size-h5: 20px;
$font-size-h4: 22px;
$font-size-h3: 24px;
$font-size-h2: 26px;
$font-size-h1: 28px;

$font-sizes: (
  "xs": $font-size-xs,
  "sm": $font-size-sm,
  "md": $font-size-md,
  "lg": $font-size-lg,
  "xl": $font-size-xl,
  "h6": $font-size-h6,
  "h5": $font-size-h5,
  "h4": $font-size-h4,
  "h3": $font-size-h3,
  "h2": $font-size-h2,
  "h1": $font-size-h1,
);

@each $key, $value in $font-sizes {
  .cipo,
  .cipo-dialog {
    .cipo-text-size--#{$key} {
      font-size: $value;
    }
  }
}
