.mat-mdc-chip-set {
  .mat-mdc-standard-chip {
    height: auto;
    padding: 4px 0;

    .mat-mdc-chip-action-label {
      text-wrap: wrap;
    }
  }

  .prefixed-chip-input {
    margin-left: 0;
  }
}

.mat-mdc-form-field.mat-mdc-form-field-type-mat-chip-grid {
  .mat-mdc-form-field-infix {
    padding: 8px 0 8px;
  }
}
