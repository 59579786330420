.cipo-table {
  &.mat-mdc-table {
    border: 1px solid var(--primary-100);
    width: 100%;
    table-layout: fixed;

    .mat-mdc-header-row {
      height: 34px;

      .mat-mdc-header-cell {
        color: var(--black);
        text-transform: uppercase;
        font-size: 12px !important;
        font-weight: 600;
        background: var(--primary-100);
        padding: 0 $spacer-sm;

        --align: start;

        &.align--center {
          --align: center;
        }
        &.align--end {
          --align: end;
        }

        .mat-sort-header-container {
          justify-content: var(--align);

          .mat-sort-header-content {
            overflow: hidden;
          }
        }
      }
    }

    .cdk-column-actions {
      text-align: end;
      width: 48px;
      text-overflow: clip;
    }

    .mat-mdc-row {
      height: 48px;

      .mat-mdc-cell {
        padding: 0 $spacer-sm;
      }
    }

    tfoot {
      outline: 2px solid $default--accent-900;

      .mat-mdc-footer-row {
        height: 35px;
        outline: 1px solid $default--accent-300;

        .mat-mdc-footer-cell {
          padding: 0 $spacer-sm;
          overflow: visible;
        }
      }
    }
  }
}

$noDataIconSize: 60px;

.cipo-empty-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);

  .mat-icon {
    height: $noDataIconSize;
    width: $noDataIconSize;
    line-height: $noDataIconSize;
    font-size: $noDataIconSize;
    color: var(--warn-300);
  }

  p {
    margin: 0;
    font-size: 18px;
    color: var(--warn-300);
  }
}

// This is mandatory to make the infinite scroll table to be displayed.
cipo-infinite-data-list {
  flex: 1 1 100%;
  box-sizing: border-box;
  max-height: 100%;
}
