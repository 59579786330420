@import "./colors";

$default-colors--light: (
  "black": $default--black,
  "white": $default--white,
  "primary-50": $default--primary-50,
  "primary-100": $default--primary-100,
  "primary-200": $default--primary-200,
  "primary-300": $default--primary-300,
  "primary-400": $default--primary-400,
  "primary-500": $default--primary-500,
  "primary-600": $default--primary-600,
  "primary-700": $default--primary-700,
  "primary-800": $default--primary-800,
  "primary-900": $default--primary-900,
  "accent-50": $default--accent-50,
  "accent-100": $default--accent-100,
  "accent-200": $default--accent-200,
  "accent-300": $default--accent-300,
  "accent-400": $default--accent-400,
  "accent-500": $default--accent-500,
  "accent-600": $default--accent-600,
  "accent-700": $default--accent-700,
  "accent-800": $default--accent-800,
  "accent-900": $default--accent-900,
  "warn-50": $default--warn-50,
  "warn-100": $default--warn-100,
  "warn-200": $default--warn-200,
  "warn-300": $default--warn-300,
  "warn-400": $default--warn-400,
  "warn-500": $default--warn-500,
  "warn-600": $default--warn-600,
  "warn-700": $default--warn-700,
  "warn-800": $default--warn-800,
  "warn-900": $default--warn-900,
);

$default-colors--dark: (
  "black": $default-dark--black,
  "white": $default-dark--white,
  "primary-50": $default-dark--primary-50,
  "primary-100": $default-dark--primary-100,
  "primary-200": $default-dark--primary-200,
  "primary-300": $default-dark--primary-300,
  "primary-400": $default-dark--primary-400,
  "primary-500": $default-dark--primary-500,
  "primary-600": $default-dark--primary-600,
  "primary-700": $default-dark--primary-700,
  "primary-800": $default-dark--primary-800,
  "primary-900": $default-dark--primary-900,
  "accent-50": $default-dark--accent-50,
  "accent-100": $default-dark--accent-100,
  "accent-200": $default-dark--accent-200,
  "accent-300": $default-dark--accent-300,
  "accent-400": $default-dark--accent-400,
  "accent-500": $default-dark--accent-500,
  "accent-600": $default-dark--accent-600,
  "accent-700": $default-dark--accent-700,
  "accent-800": $default-dark--accent-800,
  "accent-900": $default-dark--accent-900,
  "warn-50": $default-dark--warn-50,
  "warn-100": $default-dark--warn-100,
  "warn-200": $default-dark--warn-200,
  "warn-300": $default-dark--warn-300,
  "warn-400": $default-dark--warn-400,
  "warn-500": $default-dark--warn-500,
  "warn-600": $default-dark--warn-600,
  "warn-700": $default-dark--warn-700,
  "warn-800": $default-dark--warn-800,
  "warn-900": $default-dark--warn-900,
);

$default-primary-light: (
  50: $default--primary-50,
  100: $default--primary-100,
  200: $default--primary-200,
  300: $default--primary-300,
  400: $default--primary-400,
  500: $default--primary-500,
  600: $default--primary-600,
  700: $default--primary-700,
  800: $default--primary-800,
  900: $default--primary-900,
  contrast: (
    50: $default--black,
    100: $default--black,
    200: $default--black,
    300: $default--black,
    400: $default--white,
    500: $default--white,
    600: $default--white,
    700: $default--white,
    800: $default--white,
    900: $default--white,
  ),
);

$default-accent-light: (
  50: $default--accent-50,
  100: $default--accent-100,
  200: $default--accent-200,
  300: $default--accent-300,
  400: $default--accent-400,
  500: $default--accent-500,
  600: $default--accent-600,
  700: $default--accent-700,
  800: $default--accent-800,
  900: $default--accent-900,
  contrast: (
    50: $default--black,
    100: $default--black,
    200: $default--black,
    300: $default--black,
    400: $default--white,
    500: $default--white,
    600: $default--white,
    700: $default--white,
    800: $default--white,
    900: $default--white,
  ),
);

$default-warn-light: (
  50: $default--warn-50,
  100: $default--warn-100,
  200: $default--warn-200,
  300: $default--warn-300,
  400: $default--warn-400,
  500: $default--warn-500,
  600: $default--warn-600,
  700: $default--warn-700,
  800: $default--warn-800,
  900: $default--warn-900,
  contrast: (
    50: $default--black,
    100: $default--black,
    200: $default--black,
    300: $default--black,
    400: $default--white,
    500: $default--white,
    600: $default--white,
    700: $default--white,
    800: $default--white,
    900: $default--white,
  ),
);

$default-primary-dark: (
  50: $default-dark--primary-50,
  100: $default-dark--primary-100,
  200: $default-dark--primary-200,
  300: $default-dark--primary-300,
  400: $default-dark--primary-400,
  500: $default-dark--primary-500,
  600: $default-dark--primary-600,
  700: $default-dark--primary-700,
  800: $default-dark--primary-800,
  900: $default-dark--primary-900,
  contrast: (
    50: $default-dark--black,
    100: $default-dark--black,
    200: $default-dark--black,
    300: $default-dark--black,
    400: $default-dark--white,
    500: $default-dark--white,
    600: $default-dark--white,
    700: $default-dark--white,
    800: $default-dark--white,
    900: $default-dark--white,
  ),
);

$default-accent-dark: (
  50: $default-dark--accent-50,
  100: $default-dark--accent-100,
  200: $default-dark--accent-200,
  300: $default-dark--accent-300,
  400: $default-dark--accent-400,
  500: $default-dark--accent-500,
  600: $default-dark--accent-600,
  700: $default-dark--accent-700,
  800: $default-dark--accent-800,
  900: $default-dark--accent-900,
  contrast: (
    50: $default-dark--black,
    100: $default-dark--black,
    200: $default-dark--black,
    300: $default-dark--black,
    400: $default-dark--white,
    500: $default-dark--white,
    600: $default-dark--white,
    700: $default-dark--white,
    800: $default-dark--white,
    900: $default-dark--white,
  ),
);

$default-warn-dark: (
  50: $default-dark--warn-50,
  100: $default-dark--warn-100,
  200: $default-dark--warn-200,
  300: $default-dark--warn-300,
  400: $default-dark--warn-400,
  500: $default-dark--warn-500,
  600: $default-dark--warn-600,
  700: $default-dark--warn-700,
  800: $default-dark--warn-800,
  900: $default-dark--warn-900,
  contrast: (
    50: $default-dark--black,
    100: $default-dark--black,
    200: $default-dark--black,
    300: $default-dark--black,
    400: $default-dark--white,
    500: $default-dark--white,
    600: $default-dark--white,
    700: $default-dark--white,
    800: $default-dark--white,
    900: $default-dark--white,
  ),
);
