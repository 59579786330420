.mat-accordion {
  .mat-expansion-panel {
    .mat-expansion-panel-header {
      &.p-x-md {
        padding-left: $spacer-md;
        padding-right: $spacer-md;
      }

      &.p-a-0 {
        padding: 0;
      }

      &.height--auto {
        height: auto;
      }

      .mat-content.mat-content-hide-toggle {
        margin-right: 0;
      }
    }
  }
}
